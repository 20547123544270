import React from "react";
import { ArrowRight, Check } from "lucide-react";
import { motion } from "framer-motion";

interface SideImageSectionProps {
  title: string;
  description: string;
  features: { title: string; description: string }[];
  imageSrc: string;
  imageAlt: string;
  imageOnRight?: boolean;
  ctaText: string;
  onCtaClick: () => void;
  smallTitle?: string;
}

const SideImageSection: React.FC<SideImageSectionProps> = ({
  title,
  description,
  features,
  imageSrc,
  imageAlt,
  imageOnRight = false,
  ctaText,
  onCtaClick,
  smallTitle,
}) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div
      id="side-image-section"
      className="py-16"
    >
      <div className="container mx-auto px-4">
        <motion.div
          className={`w-full flex flex-col items-center justify-between ${
            imageOnRight
              ? "lg:flex-row-reverse lg:space-x-reverse lg:space-x-12"
              : "lg:flex-row lg:space-x-12"
          }`}
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          <motion.div
            className="w-full lg:w-1/2 mb-8 lg:mb-0"
            variants={itemVariants}
          >
            {smallTitle && (
              <motion.p
                variants={itemVariants}
                className="text-sm font-semibold text-blue-900 mb-2"
              >
                {smallTitle}
              </motion.p>
            )}
            <motion.h2
              variants={itemVariants}
              className="text-3xl font-bold text-blue-900 mb-4"
            >
              {title}
            </motion.h2>
            <motion.p
              variants={itemVariants}
              className="text-lg text-gray-700 mb-6"
            >
              {description}
            </motion.p>
            <motion.ul
              variants={containerVariants}
              className="space-y-6 mb-8"
            >
              {features.map((feature, index) => (
                <motion.li
                  key={index}
                  className="flex items-start"
                  variants={itemVariants}
                >
                  <Check className="flex-shrink-0 w-6 h-6 text-blue-900 mr-3 mt-1" />
                  <div>
                    <p className="font-bold text-gray-900 mb-1">
                      {feature.title}
                    </p>
                    <p className="text-gray-700">{feature.description}</p>
                  </div>
                </motion.li>
              ))}
            </motion.ul>
            <motion.button
              variants={itemVariants}
              onClick={onCtaClick}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {ctaText}
              <ArrowRight
                className="ml-2 -mr-1 h-5 w-5"
                aria-hidden="true"
              />
            </motion.button>
          </motion.div>
          <motion.div
            className="w-full lg:w-1/2"
            variants={itemVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <motion.img
              src={imageSrc}
              alt={imageAlt}
              className="rounded-lg shadow-xl w-full"
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default SideImageSection;
