import React from "react";
import * as amplitude from "@amplitude/analytics-browser";

interface ChangelogEntry {
  date: string;
  improvements: string[];
}

const changelog: ChangelogEntry[] = [
  {
    date: "2024-09-02",
    improvements: [
      "Introduced repopack functionality for creating AI-ready documentation",
      "Added folder tree when creating repopack configuration",
      "Introduced rules and packs",
      "Added support for xml and plain repopack styles",
      "Fixed bug when running repopack on repos with various main branch names",
      "Added search functionality for packs",
    ],
  },
  {
    date: "2024-09-01",
    improvements: [
      "Added blog and about pages",
      "Introduced projects feature"
    ],
  },
  {
    date: "2024-08-31",
    improvements: ["Launched AI Co-Founded platform"],
  },
];

const WhatsNewPage: React.FC = () => {
  React.useEffect(() => {
    amplitude.logEvent("WHATS_NEW_PAGE_VIEWED");
  }, []);

  return (
    <div
      id="whats-new-page"
      className="container mx-auto px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-blue-900 mb-6">What's New</h1>
      <div className="space-y-8">
        {changelog.map((entry, index) => (
          <div
            key={index}
            className="border-b border-gray-200 pb-6"
          >
            <h2 className="text-xl font-semibold text-blue-800 mb-2">
              {entry.date}
            </h2>
            <ul className="list-disc list-inside space-y-2">
              {entry.improvements.map((improvement, i) => (
                <li
                  key={i}
                  className="text-gray-700"
                >
                  {improvement}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatsNewPage;
