import React from 'react';
import { useLocation } from 'react-router-dom';

const DummyPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const errorMessage = searchParams.get('error') || 'No error message provided';

  return (
    <div id="dummy-page" className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-red-600 mb-4">Error Page</h1>
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error: </strong>
        <span className="block sm:inline">{errorMessage}</span>
      </div>
    </div>
  );
};

export default DummyPage;