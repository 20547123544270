import React, { useState, useEffect } from "react";
import {
  format,
  differenceInSeconds,
  differenceInHours,
  formatDistanceStrict,
} from "date-fns";
import EditableField from "../dashboard/EditableField";
import MyButton from "../../common/MyButton";
import {
  handleSave as apiHandleSave,
  handleComplete as apiHandleComplete,
} from "../../../api/project";
import { Project } from "../../../types/supabase";

interface ProjectHeaderProps {
  project: Project;
  viewOnly?: boolean;
  onCompleted?: () => void;
}

const ProjectHeader: React.FC<ProjectHeaderProps> = ({
  project,
  viewOnly = false,
  onCompleted,
}) => {
  const [timeStatus, setTimeStatus] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [isOverdue, setIsOverdue] = useState<boolean>(false);

  useEffect(() => {
    if (project.completed) return;

    const updateTime = () => {
      const startTime = new Date(project.created_at!);
      const endTime = new Date(startTime.getTime() + 24 * 60 * 60 * 1000);
      const now = new Date();

      const totalSeconds = 24 * 60 * 60;
      const elapsedSeconds = differenceInSeconds(now, startTime);

      if (now >= endTime) {
        const overdueDuration = differenceInHours(now, endTime);
        setTimeStatus(`Overdue by ${overdueDuration} hours`);
        setProgress(100);
        setIsOverdue(true);
      } else {
        const remainingSeconds = totalSeconds - elapsedSeconds;
        setTimeStatus(formatTimeRemaining(remainingSeconds));
        setProgress((elapsedSeconds / totalSeconds) * 100);
        setIsOverdue(false);
      }
    };

    const timer = setInterval(updateTime, 1000);
    updateTime();

    return () => clearInterval(timer);
  }, [project.created_at, project.completed]);

  const formatTimeRemaining = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <div
      id="project-header"
      className="mb-6 shadow-md rounded-md p-4"
    >
      <div className="flex justify-between items-start mb-4">
        <div className="grow">
          {viewOnly ? (
            <h2 className="text-5xl font-semibold text-blue-900 mb-2">
              {project.name}
            </h2>
          ) : (
            <EditableField
              field="name"
              label="Project Name"
              project={project}
              onSave={apiHandleSave}
              isTitle
            />
          )}
        </div>
      </div>
      {project.completed ? (
        <div className="mb-6">
          <div
            className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4"
            role="alert"
          >
            <p className="font-bold">Project Completed</p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm font-semibold text-blue-900">Started:</p>
              <p>
                {format(new Date(project.created_at!), "MM/dd/yyyy hh:mm a")}
              </p>
            </div>
            <div>
              <p className="text-sm font-semibold text-blue-900">Completed:</p>
              <p>
                {format(
                  new Date(project.end_time || new Date()),
                  "MM/dd/yyyy hh:mm a"
                )}
              </p>
            </div>
            <div className="col-span-2">
              <p className="text-sm font-semibold text-blue-900">Total Time:</p>
              <p>
                {formatDistanceStrict(
                  new Date(project.created_at!),
                  new Date(project.end_time || new Date()),
                  {
                    unit: "hour",
                  }
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            {isOverdue ? "Project Overdue" : "Time Remaining"}
          </h2>
          <div className="relative pt-1">
            <div className="text-sm font-semibold text-blue-900 mb-2">
              Started:{" "}
              {format(new Date(project.created_at!), "MM/dd/yyyy hh:mm a")}
            </div>
            <div className="overflow-hidden h-2 mb-2 text-xs flex rounded bg-blue-200">
              <div
                style={{ width: `${progress}%` }}
                className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
                  isOverdue ? "bg-red-500" : "bg-blue-500"
                }`}
              ></div>
            </div>
            <div className="flex justify-between items-center">
              <div className="text-xs font-semibold text-blue-900">0h</div>
              <div
                className={`text-sm font-bold ${
                  isOverdue ? "text-red-600" : "text-blue-600"
                }`}
              >
                {timeStatus}
              </div>
              <div className="text-xs font-semibold text-blue-900">24h</div>
            </div>
          </div>
          {!viewOnly && (
            <MyButton
              id="complete-project-button"
              onClick={() => {
                apiHandleComplete(project);
                onCompleted && onCompleted();
              }}
              className="mt-4"
            >
              Complete Project
            </MyButton>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectHeader;
