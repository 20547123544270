import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../services/supabaseClient";
import * as amplitude from "@amplitude/analytics-browser";
import { useAuth } from "../hooks/useAuth";
import { toast } from "react-toastify";
import MyButton from "../components/common/MyButton";
import Input from "../components/common/Input";
import { PREDEFINED_CATEGORIES, LANGUAGES } from "./CreateRulePage";
import { Rule } from "../types/supabase";

const EditRulePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [rule, setRule] = useState<Rule | null>(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [customTags, setCustomTags] = useState("");
  const [urls, setUrls] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [characterCount, setCharacterCount] = useState(0);

  useEffect(() => {
    if (id) {
      fetchRule();
    }
  }, [id]);

  const fetchRule = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("rules")
        .select("*")
        .eq("id", id!)
        .single();

      if (error) throw error;

      if (data) {
        setRule(data);
        setTitle(data.title);
        setContent(data.content);
        setTags(data.tags);
        setUrls(data.urls ? data.urls.join(", ") : "");
        setCharacterCount(data.content.length);

        const customTags = data.tags.filter(
          (tag) =>
            !PREDEFINED_CATEGORIES.includes(tag) && !LANGUAGES.includes(tag)
        );
        setCustomTags(customTags.join(", "));
      }
    } catch (error) {
      console.error("Error fetching rule:", error);
      setError("Failed to fetch rule. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this rule?")) {
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { error } = await supabase.from("rules").delete().eq("id", id!);

      if (error) throw error;

      toast.success("Rule deleted successfully!");
      amplitude.logEvent("RULE_DELETED", { id });
      navigate("/rules");
    } catch (error) {
      console.error("Error deleting rule:", error);
      setError("Failed to delete rule. Please try again.");
      setIsLoading(false);
    }
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setContent(newContent);
    setCharacterCount(newContent.length);
  };

  const handleTagsClick = (tag: string) => {
    const lowerTag = tag.toLowerCase();
    if (tags.includes(lowerTag)) {
      setTags(tags.filter((tag) => tag !== lowerTag));
    } else {
      setTags([...tags, lowerTag]);
    }
  };

  const handleCustomTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTags(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!user) {
      setError("You must be logged in to update a rule.");
      setIsLoading(false);
      return;
    }

    const allTags = [
      ...tags.map((tag) => tag.trim().toLowerCase()),
      ...customTags
        .split(",")
        .map((tag) => tag.trim().toLowerCase())
        .filter((tag) => tag !== ""),
    ];

    const uniqueTags = Array.from(new Set(allTags));

    const urlsArray = urls
      .split(",")
      .map((url) => url.trim())
      .filter((url) => url !== "");

    try {
      const { error } = await supabase
        .from("rules")
        .update({
          title,
          content,
          tags: uniqueTags,
          urls: urlsArray,
        })
        .eq("id", id!);

      if (error) throw error;

      toast.success("Rule updated successfully!");
      amplitude.logEvent("RULE_UPDATED", { id });
      navigate("/rules");
    } catch (error) {
      console.error("Error updating rule:", error);
      setError("Failed to update rule. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div id="edit-rule-page-loading">Loading...</div>;
  }

  if (error) {
    return <div id="edit-rule-page-error">{error}</div>;
  }

  return (
    <div
      id="edit-rule-page"
      className="container mx-auto px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-blue-900 mb-6">Edit Rule</h1>

      <form
        onSubmit={handleSubmit}
        className="space-y-6"
      >
        <Input
          id="title"
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <div className="relative">
          <Input
            id="content"
            label="Content"
            value={content}
            onChange={handleContentChange}
            required
            as="textarea"
            rows={10}
          />
          <div className="absolute bottom-2 right-2 text-sm text-gray-500">
            {characterCount} characters
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-blue-900 mb-2">Category</h2>
          <div className="flex flex-wrap gap-2 mb-4">
            {PREDEFINED_CATEGORIES.map((category) => (
              <button
                key={category}
                type="button"
                onClick={() => handleTagsClick(category.toLowerCase())}
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  tags.includes(category)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-blue-900 mb-2">Language</h2>
          <div className="flex flex-wrap gap-2 mb-4">
            {LANGUAGES.map((language) => (
              <button
                key={language}
                type="button"
                onClick={() => handleTagsClick(language)}
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  tags.includes(language.toLowerCase())
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {language}
              </button>
            ))}
          </div>
        </div>

        <Input
          id="custom-tags"
          label="Additional Tags (comma-separated)"
          value={customTags}
          onChange={handleCustomTagsChange}
        />

        <Input
          id="urls"
          label="URLs (comma-separated)"
          value={urls}
          onChange={(e) => setUrls(e.target.value)}
        />

        {error && <p className="text-red-600">{error}</p>}

        <div className="flex space-x-4">
          <MyButton
            id="submit-rule"
            type="submit"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Update Rule
          </MyButton>
          <MyButton
            id="delete-rule"
            type="button"
            variant="danger"
            onClick={handleDelete}
            disabled={isLoading}
          >
            Delete Rule
          </MyButton>
        </div>
      </form>
    </div>
  );
};

export default EditRulePage;
