// src/pages/BlogListPage.tsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as amplitude from "@amplitude/analytics-browser";
import { formatDate } from '../utils/dateUtils';
import { Bot } from 'lucide-react';

interface BlogPost {
  slug: string;
  title: string;
  date: string;
  excerpt: string;
  isAIGenerated: boolean;
}

const BlogListPage: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch('/blog-json/index.json');
      const postList = await response.json();
      setPosts(postList);
    };

    fetchPosts();
    amplitude.logEvent('BLOG_LIST_PAGE_VIEWED');
  }, []);

  return (
    <div id="blog-list-page" className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-blue-900 mb-6">Blog Posts</h1>
      <div className="grid gap-6">
        {posts.map((post) => (
          <Link key={post.slug} to={`/blog/${post.slug}`} className="block bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center mb-2">
              {post.isAIGenerated && <Bot className="text-blue-900 mr-2" size={20} />}
              <h2 className="text-xl font-semibold text-blue-900">{post.title}</h2>
            </div>
            <p className="text-gray-600 mb-2">{formatDate(post.date)}</p>
            <p className="text-gray-800">{post.excerpt}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogListPage;