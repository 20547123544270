import React from "react";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { useAuth } from "../../hooks/useAuth";
import { supabase } from "../../services/supabaseClient";

interface AuthButtonProps {
  className?: string;
}

const AuthButton: React.FC<AuthButtonProps> = ({ className = "" }) => {
  const { user, loading, signOut } = useAuth();
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      let origin = window.location.origin;
      // remove hash from URL
      origin = origin.replace("#", "");
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${origin}/auth/callback/`,
        },
      });
      if (error) throw error;
      amplitude.logEvent("USER_SIGN_IN_ATTEMPT");
    } catch (error) {
      console.error("Error signing in with Google:", error);
      alert("Error signing in with Google. Please try again.");
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      amplitude.logEvent("USER_SIGNED_OUT");
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      alert("Error signing out. Please try again.");
    }
  };

  if (loading) {
    return (
      <button
        id="auth-button-loading"
        className={`bg-gray-400 text-white font-bold py-2 px-4 rounded transition duration-300 ${className}`}
        disabled
      >
        ...
      </button>
    );
  }

  if (user) {
    return (
      <button
        id="auth-button-signout"
        onClick={handleSignOut}
        className={`bg-red-900 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded transition duration-300 ${className}`}
      >
        <span className="hidden sm:inline">Sign Out</span>
        <span className="sm:hidden">Sign Out</span>
      </button>
    );
  }

  return (
    <button
      id="auth-button-signin"
      onClick={handleSignIn}
      className={`bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ${className}`}
    >
      <span className="hidden sm:inline">Sign In With Google</span>
      <span className="sm:hidden">Sign In</span>
    </button>
  );
};

export default AuthButton;
