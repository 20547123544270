import React, { useEffect, useState } from "react";
import { supabase } from "../services/supabaseClient";
import ProjectTile from "../components/features/dashboard/ProjectTile";
import * as amplitude from "@amplitude/analytics-browser";
import { Project } from "../types/supabase";

const ProjectsPage: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProjects();
    amplitude.logEvent('PROJECTS_PAGE_VIEWED');
  }, []);

  const fetchProjects = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("projects")
      .select("*")
      .order('created_at', { ascending: false });

    if (error) {
      console.error("Error fetching projects:", error);
    } else {
      setProjects(data || []);
    }
    setLoading(false);
  };

  return (
    <div id="projects-page" className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-blue-900 mb-2">All Projects</h1>
      <p className="text-gray-600 mb-4">Projects from all users.</p>
      {loading ? (
        <p>Loading projects...</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map((project) => (
            <ProjectTile
              key={project.id}
              project={project}
              viewOnly={true}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectsPage;