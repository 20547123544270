import React, { useEffect, useState } from "react";
import { AIAwakeTimer } from "../components/features/AIAwakeTimer";
import { supabase } from "../services/supabaseClient";
import * as amplitude from "@amplitude/analytics-browser";

interface Stats {
  totalPlans: number;
  totalDocumentationItems: number;
  totalPacks: number;
  totalUsers: number;
}

const AIAwakeTimePage: React.FC = () => {
  const [stats, setStats] = useState<Stats>({
    totalPlans: 0,
    totalDocumentationItems: 0,
    totalPacks: 0,
    totalUsers: 0,
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const [
          { count: totalPlans },
          { count: totalDocumentationItems },
          { count: totalPacks },
          { count: totalUsers },
        ] = await Promise.all([
          supabase.from("plans").select("*", { count: "exact", head: true }),
          supabase.from("rules").select("*", { count: "exact", head: true }),
          supabase.from("packs").select("*", { count: "exact", head: true }),
          supabase.from("users").select("*", { count: "exact", head: true }),

        ]);

        setStats({
          totalPlans: totalPlans || 0,
          totalDocumentationItems: totalDocumentationItems || 0,
          totalPacks: totalPacks || 0,
          totalUsers: totalUsers || 0,
        });
      } catch (error) {
        console.error("Error fetching stats:", error);
        // TODO: Handle error state, possibly show an error message to the user
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
    amplitude.logEvent("AI_AWAKE_TIME_PAGE_VIEWED");
  }, []);

  return (
    <div
      id="ai-awake-time-page"
      className="container mx-auto px-4 py-8"
    >
      <div className="text-center">
        <h1 className="text-3xl font-bold text-blue-900 mb-6">
          Time Since Launch *
        </h1>
        <AIAwakeTimer />
        <p className="mt-4 text-gray-600 text-center">
          * August 31, 2024 at 7:00 PM EST
        </p>
      </div>
      {isLoading ? (
        <p className="text-center mt-8">Loading stats...</p>
      ) : (
        <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
          <a href="/plans">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold text-blue-900 mb-2">Plans
              </h2>
              <p className="text-4xl font-bold text-orange-600">
                {stats.totalPlans}
              </p>
            </div>
          </a>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-blue-900 mb-2">
              Users
            </h2>
            <p className="text-4xl font-bold text-orange-600">
              {stats.totalUsers}
            </p>
          </div>
          <a href="/packs">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold text-blue-900 mb-2">
                Packs
              </h2>
              <p className="text-4xl font-bold text-orange-600">
                {stats.totalPacks}
              </p>
            </div>
          </a>
          <a href="/rules">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold text-blue-900 mb-2">
                Rules
              </h2>
              <p className="text-4xl font-bold text-orange-600">
                {stats.totalDocumentationItems}
              </p>
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

export default AIAwakeTimePage;
