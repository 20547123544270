import React, { useCallback, useEffect, useState } from "react";
import { supabase } from "../services/supabaseClient";
import MyButton from "../components/common/MyButton";
import { Link } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import PackTile from "../components/features/packs/PackTile";
import Input from "../components/common/Input";
import { Pack } from "../types/supabase";

type SortOption = "title" | "created_at";

const ITEMS_PER_PAGE = 9;

const PacksPage: React.FC = () => {
  const [packs, setPacks] = useState<Pack[]>([]);
  const [filteredPacks, setFilteredPacks] = useState<Pack[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [sortOption, setSortOption] = useState<SortOption>("created_at");

  useEffect(() => {
    filterAndSortPacks();
  }, [searchQuery, packs, sortOption]);

  const extractBaseGitHubUrl = (fullUrl: string): string => {
    try {
      const url = new URL(fullUrl);
      const pathParts = url.pathname.split("/").filter(Boolean);
      if (pathParts.length >= 2) {
        return `https://github.com/${pathParts[0]}/${pathParts[1]}`;
      }
    } catch (error) {
      console.error("Error parsing URL:", error);
    }
    return "";
  };

  const fetchPacks = useCallback(
    async (search: string = "", page: number = 1) => {
      setIsLoading(true);
      try {
        let query = supabase.from("packs").select("*", { count: "exact" });

        if (search) {
          if (search.includes("github.com")) {
            const baseUrl = extractBaseGitHubUrl(search);
            console.log("Base URL:", baseUrl);
            query = query.eq("baseurl", baseUrl);
          } else {
            query = query.ilike("title", `%${search}%`);
          }
        }

        query = query
          .range((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE - 1)
          .order("created_at", { ascending: false });

        const { data, error, count } = await query;

        if (error) {
          throw error;
        }

        setPacks(data || []);
        setTotalCount(count || 0);
      } catch (error) {
        console.error("Error fetching packs:", error);
        alert("Failed to fetch packs. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    fetchPacks("", 1);
    amplitude.logEvent("PACKS_PAGE_VIEWED");
  }, [fetchPacks]);

  const filterAndSortPacks = useCallback(() => {
    let filtered = packs;

    if (searchQuery) {
      if (searchQuery.includes("github.com")) {
        const baseUrl = extractBaseGitHubUrl(searchQuery);
        filtered = packs.filter((pack) => pack.baseurl === baseUrl);
      } else {
        filtered = packs.filter((pack) =>
          pack.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    }

    filtered.sort((a, b) => {
      if (sortOption === "title") {
        return a.title.localeCompare(b.title);
      } else {
        return (
          new Date(b.created_at!).getTime() - new Date(a.created_at!).getTime()
        );
      }
    });

    setFilteredPacks(filtered);
  }, [packs, searchQuery, sortOption]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    amplitude.logEvent("PACKS_SEARCH", { query: e.target.value });
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSortOption(e.target.value as SortOption);
    amplitude.logEvent("PACKS_SORT", { option: e.target.value });
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchPacks(searchQuery, 1);
    amplitude.logEvent("PACKS_SEARCH", { query: searchQuery });
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchPacks(searchQuery, newPage);
  };

  return (
    <div
      id="packs-page"
      className="container mx-auto px-4 py-8"
    >
      <div className="flex flex-col items-center mb-6">
        <div className="flex flex-row justify-between w-full">
          <h1 className="text-3xl font-bold text-blue-900">Packs</h1>
          <Link to="/create-pack">
            <MyButton id="create-new-pack">Create New Pack</MyButton>
          </Link>
        </div>
        <p className="text-gray-500 w-full mt-4">
          Packs are formatted versions of GitHub repos that are optimized for
          uploading into LLMs. These are created with{" "}
          <a
            className="text-blue-500"
            href="https://github.com/yamadashy/repopack"
          >
            repopack
          </a>
          .
        </p>
      </div>

      <div className="mb-6 flex flex-col md:flex-row gap-4">
        <div className="flex-grow">
          <Input
            id="pack-search"
            type="se"
            label="Search Packs"
            value={searchQuery}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              }
            }}
            onChange={handleSearchChange}
            onPaste={(e) => {
              // Delay the search to allow the paste event to complete
              setTimeout(handleSearch, 0);
            }}
            placeholder="Enter pack title or a GitHub URL..."
          />
        </div>
        <div className="md:w-48">
          <label
            htmlFor="sort-select"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Sort By
          </label>
          <select
            id="sort-select"
            value={sortOption}
            onChange={handleSortChange}
            className="mt-1 block w-full text-base border-gray-400 border py-2.5 px-4 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="created_at">Date Created</option>
            <option value="title">Title</option>
          </select>
        </div>
      </div>

      {isLoading ? (
        <p>Loading packs...</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredPacks.map((pack) => (
            <PackTile
              key={pack.id}
              pack={pack}
            />
          ))}
        </div>
      )}

      {filteredPacks.length === 0 && !isLoading && (
        <p className="text-center text-gray-500 mt-8">
          No packs found matching your search on this page. Hit enter to search.
        </p>
      )}
      <div className="mt-4 flex justify-between items-center">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-blue-900 text-white px-4 py-2 rounded disabled:bg-gray-400"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {Math.ceil(totalCount / ITEMS_PER_PAGE)}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage * ITEMS_PER_PAGE >= totalCount}
          className="bg-blue-900 text-white px-4 py-2 rounded disabled:bg-gray-400"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PacksPage;
