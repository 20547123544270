import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as amplitude from "@amplitude/analytics-browser";

const TermsPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    amplitude.logEvent('TERMS_PAGE_VIEWED');
  }, []);

  return (
    <div id="terms-page" className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-blue-900 mb-6">Terms of Service</h1>
      <div className="prose max-w-none">
        <p className="mb-4">
          Welcome to AI Co-Founded. By using our services, you agree to these terms. Please read them carefully.
        </p>
        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">1. Use of Services</h2>
        <p className="mb-4">
          You must follow any policies made available to you within the Services. Don't misuse our Services. For example, don't interfere with our Services or try to access them using a method other than the interface and the instructions that we provide.
        </p>
        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">2. AI Co-Founder</h2>
        <p className="mb-4">
          The AI co-founder provided by our platform is a tool to assist in business operations. Users are responsible for all decisions and actions taken based on AI recommendations.
        </p>
        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">3. Privacy & Copyright Protection</h2>
        <p className="mb-4">
          AI Co-Founded's privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that AI Co-Founded can use such data in accordance with our privacy policies.
        </p>
        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">4. Modifications to Terms</h2>
        <p className="mb-4">
          We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services.
        </p>
        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">5. Liability</h2>
        <p className="mb-4">
          When permitted by law, AI Co-Founded and its suppliers and distributors will not be responsible for lost profits, revenues, or data, financial losses or indirect, special, consequential, exemplary, or punitive damages.
        </p>
        <button
          id="back-to-home-button"
          className="mt-8 bg-red-900 hover:bg-red-800 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate('/')}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default TermsPage;