import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MyButton from "../components/common/MyButton";
import Input from "../components/common/Input";
import { supabase } from "../services/supabaseClient";
import ProjectTile from "../components/features/dashboard/ProjectTile";
import { useAuth } from "../hooks/useAuth";
import * as amplitude from "@amplitude/analytics-browser";
import { toast } from "react-toastify";
import StreakBanner from "../components/features/dashboard/StreakBanner";
import { Plan, Project } from "../types/supabase";
import { Button } from "../components/ui/button";

const DashboardPage: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [username, setUsername] = useState("");
  const [originalUsername, setOriginalUsername] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [jwtToken, setJwtToken] = useState<string | null>(null);
  const [currentStreak, setCurrentStreak] = useState<number>(0);
  const [plans, setPlans] = useState<Plan[]>([]);

  const { user } = useAuth();

  useEffect(() => {
    const fetchPlans = async () => {
      if (!user) return;

      const { data, error } = await supabase
        .from("plans")
        .select("*")
        .eq("user_id", user.id)
        .order("created_at", { ascending: false });

      if (error) {
        console.error("Error fetching plans:", error);
      } else {
        setPlans(data || []);
      }
    };

    fetchPlans();
  }, [user]);

  useEffect(() => {
    const initializeDashboard = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchUsername()]);
        fetchJWTToken();
      } catch (error) {
        console.error("Error initializing dashboard:", error);
        toast.error("Failed to load dashboard data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    initializeDashboard();
  }, [user]);

  const fetchProjects = async () => {
    if (!user) return;

    const { data, error } = await supabase
      .from("projects")
      .select("*")
      .eq("user_id", user.id);

    if (error) {
      console.error("Error fetching projects:", error);
      toast.error("Failed to fetch projects. Please try again.");
    } else {
      setProjects(data || []);
    }
  };

  const fetchStreak = async () => {
    if (!user) return;

    const { data, error } = await supabase
      .from("users")
      .select("current_streak")
      .eq("id", user.id)
      .single();

    if (error) {
      console.error("Error fetching streak:", error);
      toast.error("Failed to fetch streak. Please refresh the page.");
    } else {
      setCurrentStreak(data?.current_streak || 0);
    }
  };

  const fetchUsername = async () => {
    if (!user) return;

    const { data, error } = await supabase
      .from("users")
      .select("name")
      .eq("id", user.id)
      .single();

    if (error) {
      console.error("Error fetching username:", error);
      toast.error("Failed to fetch username. Please refresh the page.");
    } else {
      setUsername(data?.name || "");
      setOriginalUsername(data?.name || "");
    }
  };

  const handleUsernameUpdate = async () => {
    if (!user) return;

    setIsUpdating(true);

    try {
      const { error } = await supabase
        .from("users")
        .update({ name: username })
        .eq("id", user.id);

      if (error) throw error;

      setOriginalUsername(username);
      amplitude.logEvent("USERNAME_UPDATED");
      toast.success("Username updated successfully!");
    } catch (error) {
      console.error("Error updating username:", error);
      toast.error("Failed to update username. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  const hasUsernameChanged = username !== originalUsername;

  const fetchJWTToken = async () => {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (session) {
      setJwtToken(session.access_token);
    }
  };

  if (isLoading) {
    return (
      <div
        id="dashboard-loading"
        className="text-center py-8"
      >
        Loading dashboard...
      </div>
    );
  }

  const copyToClipboard = () => {
    if (!jwtToken) return;
    navigator.clipboard
      .writeText(jwtToken)
      .then(() => {
        alert("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const totalProjects = projects.length;

  return (
    <div
      id="dashboard-page"
      className="container mx-auto max-w-5xl px-4 py-8"
    >
      {/* <StreakBanner streak={currentStreak} /> */}
      <div
        id="username-update-section"
        className="my-8"
      >
        <h2 className="text-2xl font-bold text-blue-900 mb-4">Your Account</h2>
        <div className="flex items-end">
          <Input
            id="username-input"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="mr-4 border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          {hasUsernameChanged && (
            <MyButton
              id="update-username-button"
              onClick={handleUsernameUpdate}
              disabled={isUpdating}
            >
              {isUpdating ? "Updating..." : "Update Username"}
            </MyButton>
          )}
        </div>
      </div>

      {false && process.env.NODE_ENV === "development" && jwtToken && (
        <div
          id="debug-jwt-section"
          className="mb-8"
        >
          <h3 className="text-xl font-semibold text-blue-900 mb-2">
            Debug: JWT Token
          </h3>
          <div className="flex items-center space-x-2 mb-4">
            <textarea
              id="jwt-display"
              readOnly
              value={jwtToken!}
              className="w-full h-12 p-2 border border-gray-300 rounded-md text-sm font-mono"
            />
            <button
              onClick={copyToClipboard}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-300"
            >
              Copy
            </button>
          </div>
        </div>
      )}

      <div className="bg-white shadow-lg rounded-lg p-8 mb-8 text-center">
        <h2 className="text-2xl font-bold text-blue-900 mb-4">
          Ready to build something?
        </h2>
        <Link to="/generate-plan">
          <Button className=" bg-green-600 text-white">
            Create New Business Plan
          </Button>
        </Link>
        {/* <Link to="/project/new">
          <MyButton
            id="add-new-project"
            className="my-5"
          >
            Start New Project
          </MyButton>
        </Link> */}
        <p className="mt-4 text-gray-600">
          Create a mini business plan and start building your next big thing.
        </p>
      </div>
      <h2 className="text-2xl font-bold text-blue-900 mt-8 mb-4">
        Your Business Plans
      </h2>
      {plans.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {plans.map((plan) => (
            <div
            key={plan.id}
            className="bg-white shadow-lg rounded-lg p-6 flex flex-col h-full"
          >
            <div className="flex-grow">
              <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
              <p className="text-gray-600 mb-2">
                {plan.idea.substring(0, 100)}...
              </p>
              <p
                className={`text-sm ${
                  plan.is_public ? "text-green-600" : "text-red-600"
                }`}
              >
                {plan.is_public ? "Public" : "Private"}
              </p>
            </div>
            <div className="mt-4">
              <Link to={`/plan/${plan.id}`} className="block">
                <Button className="w-full bg-blue-900 text-white">
                  View Plan
                </Button>
              </Link>
            </div>
          </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600">
          You haven't created any business plans yet.
        </p>
      )}
      {/*<h1 className="text-3xl font-bold text-blue-900 mb-6 ">
        Your Projects{" "}
        <span className="text-xl text-gray-600">({totalProjects})</span>
      </h1>
      

       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {projects.map((project) => (
          <ProjectTile
            key={project.id}
            project={project}
          />
        ))}
      </div>
      <Link to="/project/new">
        <MyButton
          id="add-new-project"
          className="mt-8"
        >
          Add New Project
        </MyButton>
      </Link> */}
    </div>
  );
};

export default DashboardPage;
