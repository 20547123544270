import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../services/supabaseClient";

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        const { data, error: sessionError } = await supabase.auth.getSession();

        if (sessionError) {
          throw new Error(`Authentication error: ${sessionError.message}`);
        }

        if (!data?.session) {
          throw new Error("No session found after authentication");
        }

        // Add a delay to ensure the session is fully available
        await new Promise(resolve => setTimeout(resolve, 1000));

        const { data: userData, error: userError } = await supabase
          .from("users")
          .select()
          .eq("id", data.session.user.id)
          .single();

        if (userError && userError.code !== "PGRST116") {
          throw new Error(`Error checking user: ${userError.message}`);
        }

        if (!userData) {
          const { error: insertError } = await supabase.from("users").insert(
            {
              id: data.session.user.id!,
              email: data.session.user.email!,
              name: data.session.user.user_metadata.full_name,
            },
          );

          if (insertError) {
            throw new Error(`Error creating user: ${insertError.message}`);
          }

          // Verify user creation
          const { data: newUserData, error: checkError } = await supabase
            .from("users")
            .select()
            .eq("id", data.session.user.id)
            .single();

          if (checkError || !newUserData) {
            throw new Error(`User creation verification failed: ${checkError?.message || "User not found after creation"}`);
          }
        }

        // If we've made it this far without throwing an error, redirect to dashboard
        navigate("/dashboard");
      } catch (error: any) {
        // Set the error state instead of navigating
        setError(error.message);
      }
    };

    handleAuthCallback();
  }, [navigate]);

  // If there's an error, render it in the component
  if (error) {
    return (
      <div id="auth-callback-error" className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-red-600 mb-4">Authentication Error</h1>
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  // If no error, show processing message
  return <div id="auth-callback-processing">Processing authentication...</div>;
};

export default AuthCallback;