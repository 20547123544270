import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as amplitude from "@amplitude/analytics-browser";
import MyButton from '../components/common/MyButton';

const AboutPage: React.FC = () => {
  useEffect(() => {
    amplitude.logEvent('ABOUT_PAGE_VIEWED');
  }, []);

  return (
    <div id="about-page" className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-4xl font-bold text-blue-900 mb-6">About AI Co-Founded</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-blue-900 mb-4">Our Mission</h2>
        <p className="text-lg text-gray-700 mb-4">
          At AI Co-Founded, we're on a mission to revolutionize entrepreneurship by harnessing the power of artificial intelligence to create comprehensive, data-driven business plans. Our goal is to empower aspiring entrepreneurs and seasoned business owners alike with the tools and insights they need to turn their visions into successful ventures.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-blue-900 mb-4">What We Do</h2>
        <p className="text-lg text-gray-700 mb-4">
          We provide an AI-powered platform that generates tailored business plans, offering:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4 space-y-2">
          <li>Comprehensive market analysis and competitor insights</li>
          <li>Customized financial projections and budgeting</li>
          <li>Strategic marketing and growth plans</li>
          <li>Risk assessment and mitigation strategies</li>
          <li>Operational frameworks and resource allocation advice</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-blue-900 mb-4">Our Approach</h2>
        <p className="text-lg text-gray-700 mb-4">
          By combining cutting-edge AI technology with vast databases of business intelligence, we offer:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4 space-y-2">
          <li>Data-driven insights tailored to your specific industry and market</li>
          <li>Adaptive planning that evolves with your business needs</li>
          <li>Time-efficient solution, reducing months of planning to days</li>
          <li>Affordable access to high-quality business planning for entrepreneurs at all levels</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-blue-900 mb-4">Why Choose AI Co-Founded?</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4 space-y-2">
          <li>Expertise: Our AI is trained on thousands of successful business plans and market data</li>
          <li>Efficiency: Generate comprehensive plans in a fraction of the time of traditional methods</li>
          <li>Customization: Tailored plans that adapt to your unique business idea and goals</li>
          <li>Affordability: Professional-grade business planning accessible to all entrepreneurs</li>
          <li>Continuous Updates: Our AI constantly learns and incorporates the latest market trends</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-blue-900 mb-4">Get Started Today</h2>
        <p className="text-lg text-gray-700 mb-4">
          Ready to turn your business idea into a solid plan? Let AI Co-Founded be your partner in success.
        </p>
        <Link to="/generate-plan">
          <MyButton id="generate-plan-button" className="mt-4">
            Generate Your AI Business Plan
          </MyButton>
        </Link>
      </section>

      {/* TODO: Add testimonials section once we have user feedback */}
      
      <section>
        <h2 className="text-2xl font-semibold text-blue-900 mb-4">Contact Us</h2>
        <p className="text-lg text-gray-700 mb-4">
          Have questions or need support? We're here to help you on your entrepreneurial journey.
        </p>
        {/* TODO: Add contact form or contact information */}
      </section>
    </div>
  );
};

export default AboutPage;