import React, { useEffect, useState } from "react";
import LandingPage from "./pages/LandingPage";
import { supabase } from "./services/supabaseClient";
import { Session } from "@supabase/supabase-js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/layout/Header";
import About from "./pages/AboutPage";
import AuthCallback from "./components/common/AuthCallback";
import { AuthProvider } from "./hooks/useAuth";
import * as amplitude from "@amplitude/analytics-browser";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsPage from "./pages/TermsPage";
import AIAwakeTimePage from "./pages/AIAwakeTimePage";
import NewProjectPage from "./pages/NewProjectPage";
import ProjectPage from "./pages/ProjectPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectsPage from "./pages/ProjectsPage";
import ProjectViewPage from "./pages/ProjectViewPage";
import DashboardPage from "./pages/DashboardPage";
import ProtectedRoute from "./components/common/ProtectedRoute";
import BlogPostPage from "./pages/BlogPostPage";
import BlogListPage from "./pages/BlogListPage";
import RulesPage from "./pages/RulesPage";
import CreateRulePage from "./pages/CreateRulePage";
import PacksPage from "./pages/PacksPage";
import CreatePackPage from "./pages/NewPackPage";
import PackPage from "./pages/PackPage";
import WhatsNewPage from "./pages/WhatsNewPage";
import DummyPage from "./pages/DummyPage";
import EditRulePage from "./pages/EditRulePage";
import RulePage from "./pages/RulePage";
import PlanGeneratorPage from "./pages/PlanGeneratorPage";
import BusinessIdeaPage from "./pages/BusinessIdeaPage";
import BusinessNamePage from "./pages/BusinessNamePage";
import PlanPage from "./pages/PlanPage";
import PlansPage from "./pages/PlansPage";
import PlanViewPage from "./pages/PlanViewPage";
import PricingPage from "./pages/PricingPage";
import RequestAccountDeletionPage from "./pages/RequestAccountDeletion";

function App() {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY!, {
      autocapture: true,
    });
  }, []);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);
  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route
                path="/"
                element={<LandingPage />}
              />
              <Route
                path="/about"
                element={<About />}
              />
              <Route
                path="/auth/callback"
                element={<AuthCallback />}
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/plan/:id"
                element={<PlanPage />}
              />
              <Route
                path="/plan/:id/view"
                element={<PlanViewPage />}
              />
              <Route
                path="/plans"
                element={<PlansPage />}
              />

              <Route
                path="/projects"
                element={<ProjectsPage />}
              />
              <Route
                path="/project/new"
                element={<NewProjectPage />}
              />
              <Route
                path="/project/:id"
                element={<ProjectPage />}
              />
              <Route
                path="/project/:id/view"
                element={<ProjectViewPage />}
              />
              <Route
                path="/pricing"
                element={<PricingPage />}
              />
              <Route
                path="/privacy-policy"
                element={<PrivacyPolicyPage />}
              />
              <Route
                path="/terms"
                element={<TermsPage />}
              />
              <Route
                path="/dummy"
                element={<DummyPage />}
              />
              <Route
                path="/ai-awake-time"
                element={<AIAwakeTimePage />}
              />
              <Route
                path="/request-account-deletion"
                element={<RequestAccountDeletionPage />}
              />

              <Route
                path="/blog"
                element={<BlogListPage />}
              />
              <Route
                path="/blog/:slug"
                element={<BlogPostPage />}
              />
              <Route
                path="/rules"
                element={<RulesPage />}
              />
              <Route
                path="/rule/:id/edit"
                element={<EditRulePage />}
              />
              <Route
                path="/rule/:id"
                element={<RulePage />}
              />
              <Route
                path="/packs"
                element={<PacksPage />}
              />
              <Route
                path="/pack/:id"
                element={<PackPage />}
              />
              <Route
                path="/create-pack"
                element={<CreatePackPage />}
              />
              <Route
                path="/create-rule"
                element={<CreateRulePage />}
              />
              <Route
                path="/whats-new"
                element={<WhatsNewPage />}
              />
              <Route
                path="/generate-plan"
                element={<BusinessIdeaPage />}
              />
              <Route
                path="/generate-plan/name"
                element={<BusinessNamePage />}
              />
            </Routes>
          </main>
        </div>
      </Router>
      <ToastContainer position="bottom-right" />
    </AuthProvider>
  );
}

export default App;
