import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../services/supabaseClient";
import * as amplitude from "@amplitude/analytics-browser";
import EditableField from "../components/features/dashboard/EditableField";
import ProjectRules from "../components/features/projects/ProjectRules";
import { toast } from "react-toastify";
import ProjectHeader from "../components/features/projects/ProjectHeader";
import {
  handleSave as apiHandleSave,
  handleComplete as apiHandleComplete,
} from "../api/project";
import { useAuth } from "../hooks/useAuth";
import {
  getRules,
  Project,
  ProjectWithRules,
  projectWithRulesQuery,
  Rule,
} from "../types/supabase";

const ProjectPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const user = useAuth();
  const [project, setProject] = useState<Project | null>(null);
  const [rules, setRules] = useState<Rule[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [timeRemaining, setTimeRemaining] = useState<string>("");

  useEffect(() => {
    const now = new Date();
    const start = project?.start_time ? new Date(project.start_time) : now;
    const sinceStart = now.getTime() - start.getTime();

    console.log(sinceStart);

    // If greater than 24 hours
    if (sinceStart > 86400000 && project && !project.completed) {
      setTimeRemaining("Time's up!");
    } else if (project && project.end_time && !project.completed) {
      const timer = setInterval(() => {
        const end = new Date(project.end_time!);
        const difference = end.getTime() - now.getTime();

        if (difference > 0) {
          const hours = Math.floor(difference / (1000 * 60 * 60));
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);
          setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
        } else {
          setTimeRemaining("Time's up!");
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    } else {
      // Show time until 24 hours have passed
      const difference = 86400000 - sinceStart;
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
    }
  }, [project]);

  useEffect(() => {
    const fetchProjectAndRules = async () => {
      setIsLoading(true);
      setError(null);

      try {
        // Fetch project data
        const { data: projectData, error: projectError } =
          await projectWithRulesQuery(id!);

        if (projectError) throw projectError;

        setProject(projectData);
        setRules(getRules(projectData));

        amplitude.logEvent("PROJECT_PAGE_VIEWED", { project_id: id });
      } catch (error) {
        console.error("Error fetching project and documentation:", error);
        setError("Failed to load project and documentation. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjectAndRules();
  }, [id]);

  const handleSave = async (field: keyof Project, value: string | string[]) => {
    if (!project) return;

    try {
      const { error } = await supabase
        .from("projects")
        .update({ [field]: value })
        .eq("id", project.id);

      if (error) throw error;

      setProject({ ...project, [field]: value });
      amplitude.logEvent("PROJECT_FIELD_UPDATED", { project_id: id, field });
      toast.success("Project updated successfully.");
    } catch (error) {
      console.error("Error updating project:", error);
      setError("Failed to update project. Please try again.");
    }
  };

  const handleComplete = async () => {
    if (!project || !user) return;

    try {
      const end_time = new Date().toISOString();
      const { error: projectError } = await supabase
        .from("projects")
        .update({ completed: true, end_time })
        .eq("id", project.id);

      if (projectError) throw projectError;

      // Update user's streak
      const { data: userData, error: userError } = await supabase
        .from("users")
        .select("current_streak, last_completed_project_date")
        .eq("id", user.user?.id!)
        .single();

      if (userError) throw userError;

      const startDate = new Date(project.start_time!);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      startDate.setHours(0, 0, 0, 0);

      let newStreak = userData.current_streak ?? 0;
      const lastCompletedDate = userData.last_completed_project_date
        ? new Date(userData.last_completed_project_date)
        : null;

      if (
        !lastCompletedDate ||
        (lastCompletedDate.getTime() === startDate.getTime() - 86400000 &&
          startDate.getTime() === today.getTime())
      ) {
        newStreak++;
      } else if (startDate.getTime() !== today.getTime()) {
        newStreak = 1;
      }

      const { error: updateError } = await supabase
        .from("users")
        .update({
          current_streak: newStreak,
          last_completed_project_date: startDate.toISOString().split("T")[0],
        })
        .eq("id", user.user?.id!);

      if (updateError) throw updateError;

      setProject({ ...project, completed: true, end_time });
      amplitude.logEvent("PROJECT_COMPLETED", { project_id: project.id });
      toast.success("Project completed successfully!");
    } catch (error) {
      console.error("Error completing project:", error);
      toast.error("Failed to complete project. Please try again.");
    }
  };
  if (isLoading) {
    return (
      <div
        id="project-page-loading"
        className="text-center py-8"
      >
        Loading project...
      </div>
    );
  }

  if (error) {
    return (
      <div
        id="project-page-error"
        className="text-center py-8 text-red-600"
      >
        {error}
      </div>
    );
  }

  if (!project) {
    return (
      <div
        id="project-page-not-found"
        className="text-center py-8"
      >
        Project not found.
      </div>
    );
  }

  return (
    <div
      id="project-page"
      className="container mx-auto px-4 py-8"
    >
      <ProjectHeader
        project={project}
        onCompleted={handleComplete}
      />
      {/* <EditableField
        field="tagline"
        label="Tagline"
        project={project}
        onSave={handleSave}
      /> */}
      <EditableField
        field="description"
        label="Description"
        project={project}
        onSave={handleSave}
      />
      {/* <EditableField
        field="brand_voice"
        label="Brand Voice"
        project={project}
        onSave={handleSave}
      /> */}
      <EditableField
        field="website"
        label="Website"
        project={project}
        onSave={handleSave}
      />
      <EditableField
        field="stream_url"
        label="Stream URL"
        project={project}
        onSave={handleSave}
      />
      {/* <EditableField
        field="languages"
        label="Languages"
        project={project}
        onSave={handleSave}
      /> */}
      <div className="mt-8">
        <ProjectRules
          projectId={project.id}
          projectName={project.name}
          rules={rules}
        />
      </div>
    </div>
  );
};

export default ProjectPage;
