import React, { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import MyButton from "../components/common/MyButton";
import Input from "../components/common/Input";
import { LANGUAGES, PREDEFINED_CATEGORIES } from "./CreateRulePage";
import { useRepoProcessing } from "../hooks/useRepoProcessing";
import {
  Loader,
  Folder,
  File,
  ChevronRight,
  ChevronDown,
  Minus,
  Plus,
  X,
} from "lucide-react";
import * as amplitude from "@amplitude/analytics-browser";
import { toast } from "react-toastify";

interface TreeNode {
  name: string;
  path: string;
  type: "file" | "folder";
  children?: TreeNode[];
}

const NewPackPage: React.FC = () => {
  const [title, setTitle] = useState("");
  const [githubUrl, setGithubUrl] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [customTags, setCustomTags] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [folderTree, setFolderTree] = useState<TreeNode | null>(null);
  const [selectedPaths, setSelectedPaths] = useState<string[]>([]);
  const [openNodes, setOpenNodes] = useState<Set<string>>(new Set());
  const [outputStyle, setOutputStyle] = useState<"plain" | "xml">("plain");

  const { user } = useAuth();
  const navigate = useNavigate();
  const { processRepo } = useRepoProcessing();

  useEffect(() => {
    validateGithubUrl(githubUrl);
  }, [githubUrl]);

  const validateGithubUrl = (url: string) => {
    const githubUrlRegex = /^https?:\/\/(www\.)?github\.com\/[\w-]+\/[\w.-]+/;
    setIsValidUrl(githubUrlRegex.test(url));

    if (githubUrlRegex.test(url)) {
      fetchRepoStructure(url);
    } else {
      setFolderTree(null);
    }
  };

  const fetchRepoStructure = async (url: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://xtloiorbluutzaowgmrl.supabase.co/functions/v1/fetch-repo-structure?url=${encodeURIComponent(
          url
        )}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SUPABASE_ANON_KEY}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch repository structure");
      }

      const data = await response.json();
      setFolderTree(data);
    } catch (error) {
      console.error("Error fetching repo structure:", error);
      toast.error("Failed to fetch repository structure");
    } finally {
      setIsLoading(false);
    }
  };

  const extractBaseGitHubUrl = (fullUrl: string): string => {
    try {
      const url = new URL(fullUrl);
      const pathParts = url.pathname.split("/").filter(Boolean);
      if (pathParts.length >= 2) {
        return `https://github.com/${pathParts[0]}/${pathParts[1]}`;
      }
    } catch (error) {
      console.error("Error parsing URL:", error);
    }
    return "";
  };

  const handleGithubUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGithubUrl(e.target.value);
  };

  const handleCategoryClick = (category: string) => {
    if (tags.includes(category)) {
      setTags(tags.filter((tag) => tag !== category));
    } else {
      setTags([...tags, category]);
    }
  };

  const handleCustomTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTags(e.target.value);
  };

  const handleLanguageClick = (language: string) => {
    if (selectedLanguage === language) {
      setSelectedLanguage(null);
      setTags(tags.filter((tag) => tag !== language));
    } else {
      setSelectedLanguage(language);
      setTags([...tags.filter((tag) => !LANGUAGES.includes(tag)), language]);
    }
  };

  const toggleNodeOpen = (path: string) => {
    setOpenNodes((prevOpenNodes) => {
      const newOpenNodes = new Set(prevOpenNodes);
      if (newOpenNodes.has(path)) {
        newOpenNodes.delete(path);
      } else {
        newOpenNodes.add(path);
      }
      return newOpenNodes;
    });
  };

  const clearSelectedPaths = () => {
    setSelectedPaths([]);
    amplitude.logEvent("CLEAR_SELECTED_PATHS");
    toast.info("All selected paths have been cleared");
  };

  const handlePathToggle = (path: string) => {
    setSelectedPaths((prevPaths) => {
      if (prevPaths.includes(path)) {
        // If the path is already selected, remove it
        return prevPaths.filter((p) => p !== path);
      } else {
        // If the path is not selected, add it and remove any parent paths
        return [...prevPaths.filter((p) => !path.startsWith(p)), path];
      }
    });
  };

  const renderIncludePreview = () => {
    const includeGlobs = selectedPaths.map((path) => {
      const node = findNode(folderTree!, path);
      if (node && node.type === "folder") {
        // It's a directory, so we want to include all files and subdirectories
        return `${path}/**`;
      } else {
        // It's a file, so we can use it as is
        return path;
      }
    });

    return (
      <div className="h-full">
        <pre className="bg-gray-100 p-2 rounded overflow-x-auto h-[calc(100%)] overflow-y-auto">
          {JSON.stringify(includeGlobs, null, 2)}
        </pre>
      </div>
    );
  };

  const findNode = (node: TreeNode, path: string): TreeNode | null => {
    if (node.path === path) return node;
    if (node.children) {
      for (const child of node.children) {
        const found = findNode(child, path);
        if (found) return found;
      }
    }
    return null;
  };

  const isPathSelected = (path: string) => {
    return selectedPaths.some(
      (selectedPath) =>
        path === selectedPath || path.startsWith(selectedPath + "/")
    );
  };

  const renderTree = (node: TreeNode) => {
    const isSelected = isPathSelected(node.path);
    const isOpen = openNodes.has(node.path);
    const Icon =
      node.type === "folder" ? (isOpen ? ChevronDown : ChevronRight) : File;

    return (
      <div
        key={node.path}
        className="ml-4"
      >
        <div className="flex items-center justify-between hover:bg-gray-100 p-1 rounded">
          <div
            className="flex items-center cursor-pointer flex-grow"
            onClick={() => node.type === "folder" && toggleNodeOpen(node.path)}
          >
            <Icon
              size={16}
              className="mr-2"
            />
            <span>{node.name}</span>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handlePathToggle(node.path);
            }}
            className={`p-1 rounded ${
              isSelected
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            } hover:bg-blue-600 hover:text-white`}
          >
            {isSelected ? <Minus size={16} /> : <Plus size={16} />}
          </button>
        </div>
        {node.type === "folder" && node.children && isOpen && (
          <div className="ml-4">
            {node.children.map((child) => renderTree(child))}
          </div>
        )}
      </div>
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValidUrl) {
      setError("Please enter a valid GitHub repository URL.");
      return;
    }
    setIsLoading(true);
    setError(null);

    if (!user) {
      setError("You must be logged in to create a pack.");
      setIsLoading(false);
      return;
    }

    const allTags = [
      ...tags,
      ...customTags
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag !== ""),
    ];

    const uniqueTags = Array.from(new Set(allTags));

    const baseUrl = extractBaseGitHubUrl(githubUrl);

    const config = {
      output: {
        style: outputStyle,
      },
      include: selectedPaths.map((path) =>
        path.endsWith("/") ? `${path}**` : path
      ),
    };

    try {
      setIsProcessing(true);
      await processRepo({
        title,
        githubUrl,
        baseurl: baseUrl,
        tags: uniqueTags,
        config,
        userId: user.id,
      });
      amplitude.logEvent("PACK_CREATION_STARTED", { title, githubUrl });
      toast.success(
        "Pack creation started. You'll be notified when it's ready."
      );
    } catch (error) {
      console.error("Error creating pack:", error);
      setError("Failed to create pack. Please try again.");
      setIsProcessing(false);
    } finally {
      setIsLoading(false);
    }
  };

  if (isProcessing) {
    return (
      <div
        id="pack-processing"
        className="container mx-auto px-4 py-8 text-center"
      >
        <div className="flex flex-col items-center justify-center h-[60vh]">
          <Loader className="w-16 h-16 text-blue-900 animate-spin mb-4" />
          <h2 className="text-2xl font-bold text-blue-900 mb-4">
            Creating Your Pack
          </h2>
          <p className="text-lg text-gray-600 mb-4">
            Your pack is being created. This process may take a few minutes.
          </p>
          <p className="text-md text-gray-500">
            You don't need to stay on this page. We'll notify you when it's
            ready!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      id="create-pack-page"
      className="container mx-auto px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-blue-900 ">Create New Pack</h1>
      <p className="my-4 text-gray-600">
        Please configure your repopack. After pasting in a GitHub URL, use the
        file tree to select only the files and folders you want to include in
        the pack.
      </p>
      <form
        onSubmit={handleSubmit}
        className="space-y-6"
      >
        <Input
          id="title"
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <Input
          id="github-url"
          label="GitHub URL"
          value={githubUrl}
          onChange={handleGithubUrlChange}
          required
        />

        {githubUrl && !isValidUrl && (
          <p className="text-red-500 text-sm mt-1">
            Please enter a valid GitHub repository URL.
          </p>
        )}

        {isLoading ? (
          <div className="flex items-center">
            <Loader className="animate-spin mr-2" />
            Loading repository structure...
          </div>
        ) : (
          folderTree && (
            <div className="mt-4">
              <h2 className="text-lg font-semibold mb-2">
                Select files and folders to include:
              </h2>
              <div className="lg:flex lg:space-x-4">
                <div className="lg:w-1/2 border rounded p-4 overflow-y-auto mb-4 lg:mb-0 max-h-96">
                  {renderTree(folderTree)}
                </div>
                <div className="lg:w-1/2 ">
                  {selectedPaths.length > 0 ? (
                    renderIncludePreview()
                  ) : (
                    <div className="h-full flex items-center justify-center text-gray-500">
                      Select files or folders to see the include preview
                    </div>
                  )}
                </div>
              </div>
              {selectedPaths.length > 0 && (
                <MyButton
                  id="clear-selected-paths"
                  onClick={clearSelectedPaths}
                  variant="danger"
                  disabled={selectedPaths.length === 0}
                  className="w-full flex items-center justify-center mt-4"
                >
                  <X
                    size={16}
                    className="mr-2"
                  />
                  Clear Selected Paths
                </MyButton>
              )}
            </div>
          )
        )}

        <div className="mb-4">
          <h2 className="text-xl font-semibold text-blue-900 mb-2">
            Output Style
          </h2>
          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio"
                name="outputStyle"
                value="plain"
                checked={outputStyle === "plain"}
                onChange={() => setOutputStyle("plain")}
              />
              <span className="ml-2">Plain</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio"
                name="outputStyle"
                value="xml"
                checked={outputStyle === "xml"}
                onChange={() => setOutputStyle("xml")}
              />
              <span className="ml-2">XML</span>
            </label>
          </div>
        </div>
        <div>
          <h2 className="text-xl font-semibold text-blue-900 mb-2">
            Categories
          </h2>
          <div className="flex flex-wrap gap-2 mb-4">
            {PREDEFINED_CATEGORIES.map((category) => (
              <button
                key={category}
                type="button"
                onClick={() => handleCategoryClick(category)}
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  tags.includes(category)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-blue-900 mb-2">Language</h2>
          <div className="flex flex-wrap gap-2 mb-4">
            {LANGUAGES.map((language) => (
              <button
                key={language}
                type="button"
                onClick={() => handleLanguageClick(language)}
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  selectedLanguage === language
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {language}
              </button>
            ))}
          </div>
        </div>

        <Input
          id="custom-tags"
          label="Additional Tags (comma-separated)"
          value={customTags}
          onChange={handleCustomTagsChange}
        />

        {error && <p className="text-red-600">{error}</p>}

        <MyButton
          id="submit-pack"
          type="submit"
          disabled={
            isLoading || !isValidUrl || !title || selectedPaths.length === 0
          }
          isLoading={isLoading}
        >
          Create Pack
        </MyButton>
      </form>
    </div>
  );
};

export default NewPackPage;
