import { supabase } from "../services/supabaseClient";
import * as amplitude from "@amplitude/analytics-browser";
import { toast } from "react-toastify";
import { Project, ProjectStringOrNullFields } from "../types/supabase";

export const handleSave = async (
  field: ProjectStringOrNullFields,
  value: string,
  project: Project,
) => {
  try {
    const { error } = await supabase
      .from("projects")
      .update({ [field]: value })
      .eq("id", project.id);

    if (error) throw error;

    amplitude.logEvent("PROJECT_FIELD_UPDATED", {
      project_id: project.id,
      field,
    });
    toast.success("Project updated successfully.");
    return { ...project, [field]: value };
  } catch (error) {
    console.error("Error updating project:", error);
    toast.error("Failed to update project. Please try again.");
    throw error;
  }
};

export const handleComplete = async (project: Project) => {
  try {
    const { error } = await supabase
      .from("projects")
      .update({ completed: true })
      .eq("id", project.id);

    if (error) throw error;

    amplitude.logEvent("PROJECT_COMPLETED", { project_id: project.id });
    toast.success("Congratulations! You've completed your project.");
    return { ...project, completed: true };
  } catch (error) {
    console.error("Error completing project:", error);
    toast.error("Failed to complete project. Please try again.");
    throw error;
  }
};
