import React, { useState, useEffect } from 'react';

const LAUNCH_DATE = new Date('2024-08-31T19:00:00-04:00').getTime();

export const AIAwakeTimer: React.FC = () => {
  const [timeAwake, setTimeAwake] = useState<string>('');

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date().getTime();
      const difference = now - LAUNCH_DATE;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeAwake(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    updateTimer();
    const timerId = setInterval(updateTimer, 1000);

    return () => clearInterval(timerId);
  }, []);

  return (
    <div id="ai-awake-timer" className="text-6xl font-bold text-blue-900">
      {timeAwake}
    </div>
  );
};