import React, { useState, useEffect } from "react";
import { supabase } from "../services/supabaseClient";
import * as amplitude from "@amplitude/analytics-browser";
import RuleTile from "../components/features/projects/RuleTile";
import MultiSelect from "../components/features/projects/MultiSelect";
import MyButton from "../components/common/MyButton";
import { Link } from "react-router-dom";
import { Rule } from "../types/supabase";

const RulesPage: React.FC = () => {
  const [docs, setDocs] = useState<Rule[]>([]);
  const [allTags, setAllTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchDocumentation();
    amplitude.logEvent("RULES_PAGE_VIEWED");
  }, []);

  const fetchDocumentation = async () => {
    setIsLoading(true);
    const { data, error } = await supabase.from("rules").select("*");

    if (error) {
      console.error("Error fetching rules:", error);
    } else if (data) {
      setDocs(data);

      // Extract all unique tags
      const tags = new Set(data.flatMap((doc) => doc.tags));
      setAllTags(Array.from(tags));
    }
    setIsLoading(false);
  };

  const handleTagChange = (selected: string[]) => {
    setSelectedTags(selected);
    amplitude.logEvent("RULES_TAGS_FILTERED", { tags: selected });
  };

  const filteredDocs = docs.filter(
    (doc) =>
      selectedTags.length === 0 ||
      selectedTags.every((tag) => doc.tags.includes(tag))
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      id="library-page"
      className="container mx-auto px-4 py-8"
    >
      <div className="flex flex-col justify-between items-center mb-6">
        <div className="w-full flex flex-row justify-between">
          <h1 className="text-3xl font-bold text-blue-900">Rules</h1>
          <Link to="/create-rule">
            <MyButton id="create-new-rule">
              <div className="hidden sm:block">Create New Rule</div>
              <div className="hidden max-sm:block">New</div>
            </MyButton>
          </Link>
        </div>
        <p className="text-gray-500 mt-4 w-full">
          Rules are individual pieces of documentation that can be used to steer
          the behavior of an AI model.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Filter by Tags</h2>
        <MultiSelect
          options={allTags}
          selectedOptions={selectedTags}
          onChange={handleTagChange}
          placeholder="Select tags to filter"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredDocs.map((doc) => (
          <RuleTile
            key={doc.id}
            doc={doc}
          />
        ))}
      </div>
    </div>
  );
};

export default RulesPage;
