import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { supabase } from "../services/supabaseClient";
import MyButton from "../components/common/MyButton";
import * as amplitude from "@amplitude/analytics-browser";
import { toast } from "react-toastify";
import { formatDistanceToNow, parseISO } from "date-fns";
import { ExternalLink, Link2 } from "lucide-react";
import { Pack } from "../types/supabase";

const PackPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [pack, setPack] = useState<Pack | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (id) {
      fetchPack();
      amplitude.logEvent("PACK_PAGE_VIEWED", { pack_id: id });
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const fetchPack = async () => {
    setIsLoading(true);
    const { data, error } = await supabase
      .from("packs")
      .select("*")
      .eq("id", id!)
      .single();

    if (error) {
      console.error("Error fetching pack:", error);
      toast.error("Failed to fetch pack details");
    } else {
      setPack(data);
    }
    setIsLoading(false);
  };

  const handleCopy = () => {
    if (pack) {
      navigator.clipboard.writeText(pack.content);
      amplitude.logEvent("PACK_CONTENT_COPIED", { pack_id: id });
      toast.success("Pack content copied to clipboard");
    }
  };

  const handleDownload = () => {
    if (pack) {
      try {
        const blob = new Blob([pack.content], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${pack.title}.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        amplitude.logEvent("PACK_CONTENT_DOWNLOADED", { pack_id: id });
        toast.success("Pack content downloaded successfully");
      } catch (error) {
        console.error("Error downloading pack content:", error);
        toast.error("Failed to download pack content");
      }
    }
  };

  const handleProcessRepo = async () => {
    if (!pack && !id) {
      // TODO: Implement logic to gather necessary information for a new pack
      toast.error("Creating new packs is not implemented yet");
      return;
    }

    setIsProcessing(true);
    try {
      const response = await fetch("https://api.aicofounded.com/process-repo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          githubUrl: pack?.url,
          title: pack?.title,
          tags: pack?.tags,
          config: pack?.config,
          userId: pack?.user_id,
          id: id,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to process repository");
      }

      const result = await response.json();

      if (id) {
        // Update existing pack
        setPack((prevPack) =>
          prevPack ? { ...prevPack, content: result.content } : null
        );
        amplitude.logEvent("PACK_UPDATED", { pack_id: id });
        toast.success("Pack has been updated successfully");
      } else {
        // Handle new pack creation
        amplitude.logEvent("PACK_CREATED", { pack_id: result.id });
        toast.success("New pack has been created successfully");
        navigate(`/pack/${result.id}`);
      }
    } catch (error) {
      console.error("Error processing repository:", error);
      toast.error("Failed to process repository. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  const isMoreThan24Hours = () => {
    if (!pack) return false;
    const updatedAtDate = parseISO(pack.updated_at!);
    const now = new Date();
    const differenceInHours =
      (now.getTime() - updatedAtDate.getTime()) / (1000 * 60 * 60);
    return differenceInHours > 24;
  };

  if (isLoading) {
    return (
      <div
        id="pack-page-loading"
        className="text-center py-8"
      >
        Loading pack...
      </div>
    );
  }

  const openPackUrl = () => {
    window.open(pack!.url, "_blank");
  };

  return (
    <div
      id="pack-page"
      className="container mx-auto px-4 py-8"
    >
      {pack ? (
        <>
          <h1
            className="text-3xl font-bold text-blue-900 mb-4 flex items-center gap-2 cursor-pointer"
            onClick={openPackUrl}
          >
            {pack.title}
            <ExternalLink />
          </h1>
          <p className="text-sm text-gray-500 mb-2">
            Created on: {new Date(pack.created_at!).toLocaleString()}
          </p>
          <p className="text-sm text-gray-500 mb-4">
            Last packed: {new Date(pack.updated_at!).toLocaleString()}
          </p>

          <div className="mb-4 flex space-x-4">
            <MyButton
              id="copy-pack-content"
              onClick={handleCopy}
            >
              Copy Content
            </MyButton>
            <MyButton
              id="download-pack-content"
              onClick={handleDownload}
            >
              Download Content
            </MyButton>
            {isMoreThan24Hours() && (
              <MyButton
                id="process-repo"
                onClick={handleProcessRepo}
                disabled={isProcessing}
              >
                {isProcessing ? "Processing..." : "Repack"}
              </MyButton>
            )}
          </div>
          <div className="bg-gray-100 p-4 rounded-lg mb-6">
            <textarea
              id="pack-content"
              className="w-full h-[40vh] p-4 font-mono text-sm bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={pack.content}
              readOnly
            />
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h2 className="text-xl font-semibold text-blue-900 mb-2">
              Configuration
            </h2>
            <pre
              id="pack-config"
              className="w-full h-[20vh] p-4 font-mono text-sm bg-white border border-gray-300 rounded-md overflow-auto"
            >
              {JSON.stringify(pack.config, null, 2)}
            </pre>
          </div>
        </>
      ) : (
        <div>
          <h1 className="text-3xl font-bold text-blue-900 mb-4">
            Create New Pack
          </h1>
          {/* TODO: Add form fields for creating a new pack */}
          <MyButton
            id="create-new-pack"
            onClick={handleProcessRepo}
            disabled={isProcessing}
          >
            {isProcessing ? "Creating..." : "Create Pack"}
          </MyButton>
        </div>
      )}
    </div>
  );
};

export default PackPage;
