import React from 'react';
import { Link } from 'react-router-dom';
import { Package } from 'lucide-react';
import { Pack } from '../../../types/supabase';

interface PackTileProps {
  pack: Pack;
}

const PackTile: React.FC<PackTileProps> = ({ pack }) => {
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Link
      to={`/pack/${pack.id}`}
      className="block bg-white hover:bg-orange-600 shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-all duration-300 group"
    >
      <div id={`pack-tile-${pack.id}`} className="p-6">
        <div className="flex items-center mb-2">
          <Package className="text-blue-900 group-hover:text-white mr-2" size={24} />
          <h2 className="text-xl font-semibold text-blue-900 group-hover:text-white transition-colors duration-300">
            {pack.title}
          </h2>
        </div>
        <p className="text-sm text-gray-500 group-hover:text-orange-50 mb-4 transition-colors duration-300">
          {pack.baseurl}
        </p>
        <div className="mb-4 flex flex-wrap gap-2">
          {pack.tags.map((tag) => (
            <span
              key={tag}
              className="px-2 py-1 bg-gray-200 text-gray-700 text-xs rounded group-hover:bg-blue-900 group-hover:text-white transition-colors duration-300"
            >
              {tag}
            </span>
          ))}
        </div>
        <p className="text-xs text-gray-400 group-hover:text-orange-200 transition-colors duration-300">
          Created on {formatDate(pack.created_at!)}
        </p>
      </div>
    </Link>
  );
};

export default PackTile;