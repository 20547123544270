// src/pages/PricingPage.tsx
import React, { useState } from 'react';
import * as amplitude from "@amplitude/analytics-browser";
import { Check } from 'lucide-react';
import { motion } from 'framer-motion';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import * as Switch from '@radix-ui/react-switch';
import { cva } from 'class-variance-authority';

interface PricingPlan {
  name: string;
  description: string;
  price: number;
  features: string[];
  isPopular?: boolean;
}

const toggleGroupItemStyles = cva(
  'px-4 py-2 rounded-full data-[state=on]:bg-white data-[state=on]:shadow-sm transition-all',
  {
    variants: {
      variant: {
        default: 'bg-gray-100 text-gray-700 hover:bg-gray-200',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

const PricingPage: React.FC = () => {
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('monthly');

  const yearlyDiscount = 0.2; // 20% discount for yearly plans

  const plans: PricingPlan[] = [
    {
      name: "Free",
      description: "For individuals and small projects",
      price: 0,
      features: [
        "1 AI-generated mini plan",
        "Basic analytics",
        "Community support",
      ],
    },
    {
      name: "Pro",
      description: "A plan that scales with your rapidly growing business",
      price: 29,
      features: [
        "Unlimited AI-generated mini plans",
        "Advanced analytics",
        "24-hour support response time",
        "AI-powered business insights",
      ],
      isPopular: true,
    },
    {
      name: "Enterprise",
      description: "Dedicated support and infrastructure for your company",
      price: 99,
      features: [
        "Everything in Pro",
        "Unlimited team members",
        "Advanced AI customization",
        "1-hour, dedicated support response time",
        "Custom integrations",
      ],
    },
  ];

  const handleBillingCycleChange = (cycle: 'monthly' | 'yearly') => {
    setBillingCycle(cycle);
    amplitude.logEvent('BILLING_CYCLE_CHANGED', { cycle });
  };

  const calculatePrice = (basePrice: number) => {
    if (billingCycle === 'yearly') {
      return (basePrice * 12 * (1 - yearlyDiscount)).toFixed(2);
    }
    return basePrice.toFixed(2);
  };

  return (
    <motion.div
      id="pricing-page"
      className="container mx-auto px-4 py-16"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.h1
        className="text-4xl font-bold text-center mb-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        Pricing plans for teams of all sizes
      </motion.h1>
      <motion.p
        className="text-center text-gray-600 mb-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        Choose the perfect plan for your business needs. Save with our yearly billing option.
      </motion.p>

      <motion.div
        className="flex justify-center mb-8"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.6, duration: 0.5 }}
      >
        <ToggleGroup.Root
          className="bg-gray-100 p-1 rounded-full"
          type="single"
          value={billingCycle}
          onValueChange={(value) => value && handleBillingCycleChange(value as 'monthly' | 'yearly')}
        >
          <ToggleGroup.Item
            className={toggleGroupItemStyles()}
            value="monthly"
          >
            Monthly
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className={toggleGroupItemStyles()}
            value="yearly"
          >
            Yearly (20% off)
          </ToggleGroup.Item>
        </ToggleGroup.Root>
      </motion.div>

      <div className="grid md:grid-cols-3 gap-8">
        {plans.map((plan, index) => (
          <motion.div
            key={plan.name}
            className={`bg-white p-8 rounded-lg shadow-md flex flex-col ${plan.isPopular ? 'border-2 border-blue-500' : ''}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 * (index + 1), duration: 0.5 }}
          >
            {plan.isPopular && (
              <span className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-semibold self-start mb-4">
                Most popular
              </span>
            )}
            <h2 className="text-2xl font-bold mb-2">{plan.name}</h2>
            <p className="text-gray-600 mb-4">{plan.description}</p>
            <div className="text-4xl font-bold mb-4">
              ${calculatePrice(plan.price)}
              <span className="text-base font-normal text-gray-600">
                /{billingCycle === 'monthly' ? 'month' : 'year'}
              </span>
            </div>
            <ul className="mb-8 flex-grow">
              {plan.features.map((feature, featureIndex) => (
                <motion.li
                  key={featureIndex}
                  className="flex items-center mb-2"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 * featureIndex, duration: 0.5 }}
                >
                  <Check className="text-green-500 mr-2" size={20} />
                  <span>{feature}</span>
                </motion.li>
              ))}
            </ul>
            <motion.button
              className={`w-full py-2 rounded-md ${plan.isPopular ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} font-semibold`}
              onClick={() => amplitude.logEvent('PLAN_SELECTED', { plan: plan.name, billingCycle })}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {plan.price === 0 ? 'Get started' : 'Buy plan'}
            </motion.button>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default PricingPage;