import React from "react";
import { useNavigate } from "react-router-dom";
import CenterHero from "../components/layout/CenterHero";
import SideHero from "../components/layout/SideHero";
import FeaturesSection from "../components/layout/FeaturesSection";
import Footer from "../components/layout/Footer";
import Logo from "../components/common/Logo";
import * as amplitude from "@amplitude/analytics-browser";
import { useAuth } from "../hooks/useAuth";
import { toast } from "react-toastify";
import SideImageSection from "../components/layout/SideImageSection";
import FAQSection from "../components/layout/FaqSection";

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const user = useAuth();

  return (
    <div className="min-h-screen bg-gray-100">
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <CenterHero
            announcement={{
              text: "Join a community of builders!",
              link: "/ai-awake-time",
            }}
            title="AI-Generated Mini Plans"
            subtitle="Get a head start on your next project with an AI-generated mini business plan."
            primaryCTA={{
              text: "Generate a Plan 🚀",
              onClick: () => {
                amplitude.logEvent("cta_clicked", {
                  location: "landing_page",
                  cta: "primary",
                });

                if (user.user) {
                  navigate("/generate-plan");
                } else {
                  toast.error(
                    "You must be logged in to generate a business plan"
                  );
                }
              },
            }}
            secondaryCTA={{
              text: "See All Plans",
              link: "/plans",
            }}
          />
          <FeaturesSection
            label="AI-Powered Business Planning"
            title="Transform Your Vision into Strategy"
            subtitle="Our AI doesn't just generate business plans; it crafts comprehensive strategies tailored to your unique entrepreneurial vision."
          />

          <SideImageSection
            smallTitle="AI-Crafted Business Plans"
            title="Your Idea, Amplified by AI"
            description="Answer a few questions about your business concept, and watch as our AI transforms it into a robust, data-driven business plan."
            features={[
              {
                title: "Informed Decision Making",
                description:
                  "Leverage our AI's vast knowledge base to make strategic decisions backed by current market insights and trends.",
              },
              {
                title: "Comprehensive Strategy",
                description:
                  "Explore multiple marketing approaches, business models, and target demographics - all tailored to your unique vision.",
              },
              {
                title: "Clear Roadmap to Success",
                description:
                  "Get a detailed, step-by-step plan that outlines your path from concept to market leader.",
              },
            ]}
            imageSrc={require("../assets/ship.png")}
            imageAlt="AI-powered business plan interface"
            imageOnRight={false}
            ctaText="Create Your AI-Powered Plan"
            onCtaClick={() => {
              console.log("Generate plan clicked");
              if (user.user) {
                navigate("/generate-plan");
              } else {
                toast.error(
                  "You must be logged in to generate a business plan"
                );
              }
            }}
          />

          <SideImageSection
            smallTitle="AI Business Consultant"
            title="Your 24/7 Strategic Partner"
            description="Engage with our AI consultant to refine your strategy, generate content, and get answers to your most pressing business questions."
            features={[
              {
                title: "Tailored Prompts & Insights",
                description:
                  "Access a wealth of industry-specific prompts and questions to spark new ideas and strategies.",
              },
              {
                title: "Personalized Assistance",
                description:
                  "Our AI understands your business and brand voice, providing contextually relevant advice and content.",
              },
              {
                title: "Cutting-Edge AI Technology",
                description:
                  "Powered by the latest GPT model, ensuring you receive the most advanced AI-driven insights available.",
              },
            ]}
            imageSrc={require("../assets/astronaut.png")}
            imageAlt="AI Business Consultant Interface"
            imageOnRight={true}
            ctaText="Consult Your AI Partner"
            onCtaClick={() => {
              console.log("AI consultation initiated");

              if (user.user) {
                navigate("/generate-plan");
              } else {
                toast.error(
                  "You must be logged in to generate a business plan"
                );
              }
            }}
          />

          <SideImageSection
            smallTitle="AI Content Creation"
            title="Amplify Your Brand Voice"
            description="Harness the power of AI to create compelling content that resonates with your audience and aligns with your business goals."
            features={[
              {
                title: "Diverse Content Templates",
                description:
                  "Choose from over 50 templates designed for various content types and business needs.",
              },
              {
                title: "Brand-Aligned Creation",
                description:
                  "Our AI understands your brand voice and business context, ensuring consistent and relevant content.",
              },
              {
                title: "State-of-the-Art Generation",
                description:
                  "Utilize advanced language models to create content that's indistinguishable from human-written copy.",
              },
            ]}
            imageSrc={require("../assets/station.png")}
            imageAlt="AI Content Creation Dashboard"
            imageOnRight={false}
            ctaText="Start Creating AI-Powered Content"
            onCtaClick={() => {
              console.log("Content creation tool accessed");

              if (user.user) {
                navigate("/generate-plan");
              } else {
                toast.error(
                  "You must be logged in to generate a business plan"
                );
              }
            }}
          />
        </div>
        <FAQSection />
        <Footer
          logo={<Logo />}
          description="AI Co-Founded: Empowering visionaries with AI-crafted business plans. Transform your ideas into strategic, data-driven ventures."
          copyright="© 2024 AI Co-Founded, Inc. All rights reserved."
        />
      </main>
    </div>
  );
};

export default LandingPage;
