import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../services/supabaseClient";
import * as amplitude from "@amplitude/analytics-browser";
import { Plan } from "../types/supabase";
import { Button } from "../components/ui/button";

const PlanViewPage: React.FC = () => {
  const [plan, setPlan] = useState<Plan | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlan = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const { data, error } = await supabase
          .from("plans")
          .select("*")
          .eq("id", id!)
          .single();

        if (error) throw error;

        setPlan(data);
        amplitude.logEvent("PLAN_PAGE_VIEWED", { plan_id: id });
      } catch (error) {
        console.error("Error fetching plan:", error);
        setError("Failed to load plan. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlan();
  }, [id]);

  if (isLoading) {
    return <div id="plan-page-loading" className="text-center py-8">Loading plan...</div>;
  }

  if (error) {
    return <div id="plan-page-error" className="text-center py-8 text-red-600">{error}</div>;
  }

  if (!plan) {
    return <div id="plan-page-not-found" className="text-center py-8">Plan not found.</div>;
  }

  return (
    <div id="plan-page" className="container mx-auto max-w-5xl px-4 py-8">
      <h1 className="text-3xl font-bold text-blue-900 mb-6">{plan.name}</h1>
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-2">Business Idea</h2>
        <p className="text-gray-700">{plan.idea}</p>
      </div>
      <div className="bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Mini Business Plan</h2>
        <pre className="whitespace-pre-wrap text-gray-700">{plan.mini_plan}</pre>
      </div>
      <Button
        id="back-to-dashboard"
        onClick={() => navigate("/dashboard")}
        className="mt-6 bg-blue-900 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-800"
      >
        Back to Dashboard
      </Button>
    </div>
  );
};

export default PlanViewPage;