import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { Button } from "../components/ui/button";

const BusinessIdeaPage: React.FC = () => {
  const [businessIdea, setBusinessIdea] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedIdea = localStorage.getItem("businessIdea");
    if (storedIdea) {
      setBusinessIdea(storedIdea);
    }
    amplitude.logEvent("BUSINESS_IDEA_PAGE_VIEWED");
  }, []);

  const handleNext = () => {
    if (businessIdea.trim()) {
      localStorage.setItem("businessIdea", businessIdea);
      navigate("/generate-plan/name");
    } else {
      // TODO: Add proper validation and error message
      alert("Please enter a business idea");
    }
  };

  return (
    <div id="business-idea-page" className="container mx-auto max-w-2xl px-4 py-8">
      <h1 className="text-3xl font-bold text-blue-900 mb-6">
        Describe Your Business Idea
      </h1>
      <div className="space-y-6">
        <div>
          <label
            htmlFor="business-idea"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Your Business Idea
          </label>
          <textarea
            id="business-idea"
            value={businessIdea}
            onChange={(e) => setBusinessIdea(e.target.value)}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
            rows={6}
            placeholder="Describe your business idea in detail..."
            required
          />
        </div>
        <Button
          id="next-button"
          onClick={handleNext}
          className="w-full bg-blue-900 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-800"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default BusinessIdeaPage;