import React from "react";

interface ButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  variant?: "primary" | "secondary" | "danger";
  isLoading?: boolean;
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
  id?: string;
}

const MyButton: React.FC<ButtonProps> = ({
  onClick,
  disabled = false,
  className = "",
  variant = "primary",
  isLoading = false,
  children,
  type = "button",
  id,
}) => {
  const baseClasses = "font-bold py-2 px-4 rounded transition duration-300";

  const variantClasses = {
    primary: "bg-blue-900 hover:bg-blue-800 text-white",
    secondary: "bg-orange-600 hover:bg-orange-500 text-white",
    danger: "bg-red-900 hover:bg-red-800 text-white",
  };

  const loadingClasses = "bg-gray-400 text-white cursor-not-allowed";

  const buttonClasses = `
    ${baseClasses}
    ${isLoading || disabled ? loadingClasses : variantClasses[variant]}
    ${className}
  `.trim();

  return (
    <button
      id={id}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={buttonClasses}
      type={type}
    >
      {isLoading ? "Loading..." : children}
    </button>
  );
};

export default MyButton;
