// src/utils/dateUtils.ts

export const formatDate = (
  dateString: string,
  includeTime: boolean = false
): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  if (includeTime) {
    options.hour = "2-digit";
    options.minute = "2-digit";
  }

  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const formatDateWithTime = (dateString: string): string => {
  return formatDate(dateString, true);
};
