import React, { useEffect, useRef } from 'react';
import { X, Copy } from 'lucide-react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  onCopy?: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children, onCopy }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-hidden bg-black bg-opacity-50 flex justify-center items-center">
      <div ref={modalRef} className="bg-white w-full max-w-4xl mx-4 rounded-lg shadow-xl flex flex-col h-[90vh]">
        <div className="flex justify-between items-center border-b p-4">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="flex-grow overflow-y-auto p-4">
          {children}
        </div>
        <div className="border-t p-4 flex justify-end items-center">
          {onCopy && (
            <button
              onClick={onCopy}
              className="flex items-center text-green-600 hover:text-green-800 mr-4"
            >
              <Copy size={20} className="mr-1" />
              Copy Content
            </button>
          )}
          <button
            onClick={onClose}
            className="flex items-center text-red-600 hover:text-red-800"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;