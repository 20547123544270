import { toast } from 'react-toastify';
import * as amplitude from "@amplitude/analytics-browser";
import { useNavigate } from 'react-router-dom';

interface RepoData {
  githubUrl: string;
  baseurl: string;
  title: string;
  userId: string;
  tags?: string[];
  config?: any;
}

interface JobStatus {
  state: 'waiting' | 'active' | 'completed' | 'failed';
  result?: any;
  reason?: string;
}

export function useRepoProcessing() {
  const navigate = useNavigate();

  async function processRepo(repoData: RepoData) {
    try {
      const response = await fetch("https://api.aicofounded.com/process-repo", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(repoData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to start repo processing");
      }

      const { jobId } = await response.json();
      toast.info("Repository processing started. This may take a few minutes.");

      // Start polling for job status
      pollJobStatus(jobId);
    } catch (error) {
      console.error("Error starting repo processing:", error);
      toast.error("Failed to start repository processing. Please try again.");
    }
  }

  async function pollJobStatus(jobId: string) {
    console.log(`Polling job status for job ${jobId}`);
    const maxAttempts = 60; // 5 minutes maximum polling time
    let attempts = 0;

    const pollInterval = setInterval(async () => {
      try {
        const response = await fetch(`https://api.aicofounded.com/job-status/${jobId}`);

        if (!response.ok) {
          throw new Error("Failed to fetch job status");
        }

        const jobStatus: JobStatus = await response.json();
        console.log(`Job ${jobId} status: ${jobStatus.state}`);

        switch (jobStatus.state) {
          case "completed":
            clearInterval(pollInterval);
            handleJobCompletion(jobStatus.result);
            break;
          case "failed":
            clearInterval(pollInterval);
            handleJobFailure(jobStatus.reason);
            break;
          case "active":
            toast.info("Repository processing in progress...", { autoClose: 3000 });
            break;
          case "waiting":
            // Do nothing, continue polling
            break;
        }

        attempts++;
        if (attempts >= maxAttempts) {
          clearInterval(pollInterval);
          handleJobTimeout();
        }
      } catch (error) {
        console.error("Error polling job status:", error);
        clearInterval(pollInterval);
        toast.error("Error checking job status. Please try again later.");
      }
    }, 5000); // Poll every 5 seconds

    // Cleanup function to clear interval if component unmounts
    return () => clearInterval(pollInterval);
  }

  function handleJobCompletion(result: any) {
    console.log("Job completed successfully:", result);
    amplitude.logEvent("PACK_CREATED", {
      packId: result.id,
    });
    toast.success("Pack created successfully!");
    navigate("/packs");
  }

  function handleJobFailure(reason?: string) {
    console.error("Job failed:", reason);
    toast.error("Failed to create pack. Please try again.");
  }

  function handleJobTimeout() {
    console.error("Job processing timed out");
    toast.error("Pack creation is taking longer than expected. Please check back later.");
  }

  return { processRepo };
}