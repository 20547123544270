// src/types/supabase.ts

import { QueryData } from "@supabase/supabase-js/dist/module/lib/types";
import { supabase } from "../services/supabaseClient";
import { Tables } from "./database.types";

export type User = Tables<"users">;
export type Project = Tables<"projects">;
export type Pack = Tables<"packs">;
export type Rule = Tables<"rules">;
export type Plan = Tables<"plans">;

type NumberFields<T> = {
  [K in keyof T]: T[K] extends number ? K : never;
}[keyof T];

type StringFields<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

type BooleanFields<T> = {
  [K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

type StringOrNullFields<T> = {
  [K in keyof T]: T[K] extends string | null ? K : never;
}[keyof T];

export type ProjectStringOrNullFields = StringOrNullFields<Project>;
export type ProjectNumberFields = NumberFields<Project>;
export type ProjectStringFields = StringFields<Project>;
export type ProjectBooleanFields = BooleanFields<Project>;

// Joined types
export type ProjectWithRules = QueryData<
  ReturnType<typeof projectWithRulesQuery>
>;

// Utility function to extend functionality
export function getRules(projectData: ProjectWithRules) {
  return projectData.project_rules.map((p) => p.rules ?? []).flat();
}

export type PackWithRules = QueryData<
  ReturnType<typeof packWithRulesQuery>
>;

// Query functions
export const projectWithRulesQuery = (id: string) =>
  supabase
    .from("projects")
    .select(`
        *,
        project_rules (
            rule_id,
            rules (
                *
            )
          )
      `)
    .eq("id", id)
    .single();

export const packWithRulesQuery = () =>
  supabase
    .from("packs")
    .select(`
        *,
        pack_rules (
          rule_id,
          rules (
            *
          )
        )
      `);
