import React from "react";

interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    "as"
  > {
  label: string;
  as?: "input" | "textarea";
  rows?: number;
}

const Input: React.FC<InputProps> = ({
  label,
  id,
  as = "input",
  rows,
  ...props
}) => {
  const inputClasses =
    "mt-1 block w-full rounded-md p-2 border border-gray-400 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50";

  const InputComponent = as === "textarea" ? "textarea" : "input";

  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <InputComponent
        id={id}
        className={inputClasses}
        rows={as === "textarea" ? rows : undefined}
        {...props}
      />
    </div>
  );
};

export default Input;