import React from "react";
import { motion } from "framer-motion";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";

interface CenterHeroProps {
  announcement?: {
    text: string;
    link: string;
  };
  title: string;
  subtitle: string;
  primaryCTA: {
    text: string;
    onClick: () => void;
  };
  secondaryCTA?: {
    text: string;
    link: string;
  };
}

const CenterHero: React.FC<CenterHeroProps> = ({
  announcement,
  title,
  subtitle,
  primaryCTA,
  secondaryCTA,
}) => {
  return (
    <motion.div
      id="home"
      className="min-h-[600px] flex flex-col items-center justify-center text-center px-4 py-16 bg-gradient-to-br"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {announcement && (
        <motion.div
          className="mb-8"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Badge variant="outline" className="py-2 px-4">
            <a
              href={announcement.link}
              className="text-sm text-gray-600 hover:text-gray-800"
            >
              {announcement.text}{" "}
              <span className="text-red-800 font-medium">View stats →</span>
            </a>
          </Badge>
        </motion.div>
      )}
      <motion.h1
        className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 max-w-4xl bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-red-900"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        {title}
      </motion.h1>
      <motion.p
        className="text-lg md:text-xl text-gray-600 mb-8 max-w-2xl"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        {subtitle}
      </motion.p>
      <motion.div
        className="flex flex-col sm:flex-row items-center gap-4"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <Button
          onClick={primaryCTA.onClick}
          size="lg"
          className="bg-red-900 hover:bg-red-800 text-white"
        >
          {primaryCTA.text}
        </Button>
        {secondaryCTA && (
          <Button variant="ghost" size="lg" asChild>
            <a href={secondaryCTA.link} className="text-red-900 hover:text-red-800">
              {secondaryCTA.text} →
            </a>
          </Button>
        )}
      </motion.div>
    </motion.div>
  );
};

export default CenterHero;