import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../services/supabaseClient";
import * as amplitude from "@amplitude/analytics-browser";
import ProjectRules from "../components/features/projects/ProjectRules";
import ProjectHeader from "../components/features/projects/ProjectHeader";
import { getRules, Project, projectWithRulesQuery, Rule } from "../types/supabase";

const ProjectViewPage: React.FC = () => {
  const [project, setProject] = useState<Project | null>(null);
  const [rules, setRules] = useState<Rule[]>([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchProjectAndDocs = async () => {
      setLoading(true);
      try {
        // Fetch project data
        const { data: projectData, error: projectError } =
          await projectWithRulesQuery(id!);

        if (projectError) throw projectError;
        setProject(projectData);
        setRules(getRules(projectData));

        amplitude.logEvent("PROJECT_VIEW_PAGE_VIEWED", { projectId: id });
      } catch (error) {
        console.error("Error fetching project:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectAndDocs();
  }, [id]);

  if (loading) {
    return <div id="project-view-page-loading">Loading project...</div>;
  }

  if (!project) {
    return <div id="project-view-page-not-found">Project not found</div>;
  }

  return (
    <div
      id="project-view-page"
      className="container mx-auto max-w-5xl px-4 py-8"
    >
      <ProjectHeader
        project={project}
        viewOnly
      />
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Description</h2>
        <p className="text-gray-700">{project.description}</p>
      </div>
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Language</h2>
        <p className="text-gray-700">{project.languages?.join(", ")}</p>
      </div>

      <ProjectRules
        projectId={project.id}
        projectName={project.name}
        rules={rules}
      />
    </div>
  );
};

export default ProjectViewPage;
