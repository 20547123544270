import React from "react";
import {
  Facebook,
  Instagram,
  Twitter,
  Github,
  Youtube,
  MessageCircle,
  Cloud,
} from "lucide-react";

interface FooterColumn {
  title: string;
  links: { name: string; href: string }[];
}

interface FooterProps {
  logo: React.ReactNode;
  description: string;
  copyright: string;
}

interface SocialIconProps {
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  link: string;
}

const SocialIcon: React.FC<SocialIconProps> = ({ Icon, link }) => (
  <a
    href={link}
    className="text-gray-400 hover:text-gray-500"
  >
    <span className="sr-only">Social media</span>
    <Icon className="h-6 w-6" />
  </a>
);

const Footer: React.FC<FooterProps> = ({ logo, description, copyright }) => {
  const columns = [
    {
      title: "Products",
      links: [
        {
          name: "Repopacks",
          href: "/packs",
        },
        {
          name: "AI Rules",
          href: "/rules",
        },
        {
          name: "HustleGPT Companion Guide",
          href: "https://codeontherocks.gumroad.com/l/abqwfw",
        },
        {
          name: "Midjourney Reference Gallery",
          href: "https://codeontherocks.gumroad.com/l/lsxlk?layout=profile",
        },
        {
          name: "AI Tools Directory",
          href: "https://codeontherocks.gumroad.com/l/nccpm?layout=profile",
        },
      ],
    },
    /* {
      title: "Support",
      links: [
        { name: "Documentation", href: "#" },
        { name: "API Status", href: "#" },
        { name: "Guides", href: "#" },
        { name: "Community", href: "#" },
      ],
    }, */
    {
      title: "Company",
      links: [
        { name: "About", href: "/about" },
        { name: "Blog", href: "/blog" },
        // { name: "Careers", href: "#" },
        // { name: "Press", href: "#" },
        // { name: "Partners", href: "#" },
      ],
    },
    {
      title: "Legal",
      links: [
        { name: "Privacy", href: "/privacy-policy" },
        { name: "Terms", href: "/terms" },
      ],
    },
  ];
  return (
    <footer className="">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            {logo}
            <p className="text-gray-500 text-base">{description}</p>
            <div className="flex space-x-6">
              <SocialIcon
                Icon={Twitter}
                link="https://x.com/code_ontherocks"
              />
              <SocialIcon
                Icon={Github}
                link="https://github.com/jtmuller5/The-HustleGPT-Challenge"
              />
              <SocialIcon
                Icon={Youtube}
                link="https://www.youtube.com/@CodeOnTheRocksDev"
              />
              <SocialIcon
                Icon={MessageCircle}
                link="https://www.threads.net/@codeontherocks"
              />
              <SocialIcon
                Icon={Cloud}
                link="https://bsky.app/profile/codeontherocks.bsky.social"
              />
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {columns.slice(0, 2).map((column, index) => (
                <div key={index}>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase pt-8">
                    {column.title}
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {column.links.map((link, linkIndex) => (
                      <li key={linkIndex}>
                        <a
                          href={link.href}
                          className="text-base text-gray-500 hover:text-gray-900"
                        >
                          {link.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {columns.slice(2).map((column, index) => (
                <div key={index}>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase pt-8">
                    {column.title}
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {column.links.map((link, linkIndex) => (
                      <li key={linkIndex}>
                        <a
                          href={link.href}
                          className="text-base text-gray-500 hover:text-gray-900"
                        >
                          {link.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 xl:text-center">{copyright}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
