import React from 'react';

interface LogoProps {
  className?: string;
  size?: 'small' | 'medium' | 'large';
  showShadow?: boolean;
}

const Logo: React.FC<LogoProps> = ({ className = '', size = 'medium', showShadow = true }) => {
  const sizeClasses = {
    small: 'h-8 w-8',
    medium: 'h-16 w-16',
    large: 'h-24 w-24',
  };

  const shadowClass = showShadow ? 'shadow' : '';
  const sizeClass = sizeClasses[size];

  return (
    <img
      className={`rounded-md ${sizeClass} ${shadowClass} ${className}`}
      src={require("../../assets/logo.jpg")}
      alt="AI Cofounded Logo"
    />
  );
};

export default Logo;