import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { supabase } from "../services/supabaseClient";
import * as amplitude from "@amplitude/analytics-browser";
import { Plan } from "../types/supabase";
import { Button } from "../components/ui/button";
import { useAuth } from "../hooks/useAuth";

const PlansPage: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchPlans = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const { data, error } = await supabase
          .from("plans")
          .select("*")
          .eq("is_public", true)
          .order("created_at", { ascending: false });

        if (error) throw error;

        setPlans(data);
        amplitude.logEvent("PLANS_PAGE_VIEWED");
      } catch (error) {
        console.error("Error fetching plans:", error);
        setError("Failed to load plans. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlans();
  }, []);

  if (isLoading) {
    return (
      <div
        id="plans-page-loading"
        className="text-center py-8"
      >
        Loading plans...
      </div>
    );
  }

  if (error) {
    return (
      <div
        id="plans-page-error"
        className="text-center py-8 text-red-600"
      >
        {error}
      </div>
    );
  }

  return (
    <div
      id="plans-page"
      className="container mx-auto px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-blue-900 mb-6">
        Public Business Plans
      </h1>
      {plans.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className="bg-white shadow-lg rounded-lg p-6 flex flex-col h-full"
            >
              <div className="flex-grow">
                <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
                <p className="text-gray-600">
                  {plan.idea.substring(0, 100)}...
                </p>
              </div>
              <div className="mt-4">
                <Link
                  to={
                    plan.user_id == user?.id
                      ? `/plan/${plan.id}`
                      : `/plan/${plan.id}/view`
                  }
                  className="block"
                >
                  <Button
                    id={`view-plan-${plan.id}`}
                    className="w-full bg-blue-900 text-white"
                  >
                    View Plan
                  </Button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600">No public plans available.</p>
      )}
    </div>
  );
};

export default PlansPage;
