import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as amplitude from "@amplitude/analytics-browser";

const PrivacyPolicyPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    amplitude.logEvent('PRIVACY_POLICY_PAGE_VIEWED');
  }, []);

  return (
    <div id="privacy-policy-page" className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-blue-900 mb-6">Privacy Policy for AI Co-Founded Mobile App</h1>
      <div className="prose max-w-none">
        <p className="mb-4">
          At AI Co-Founded, we take your privacy seriously. This policy describes how we collect, use, and protect your personal information when you use our mobile application.
        </p>

        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">Information We Collect</h2>
        <p className="mb-4">
          We collect information you provide directly to us when you create an account, use our services, or communicate with us. This may include:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Personal information (e.g., name, email address)</li>
          <li>Business information you input for your AI-generated business plans</li>
          <li>Usage data and app interactions</li>
          <li>Device information (e.g., device type, operating system)</li>
        </ul>

        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">How We Use Your Information</h2>
        <p className="mb-4">
          We use your information to:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Provide, maintain, and improve our mobile app services</li>
          <li>Generate AI-powered business plans tailored to your inputs</li>
          <li>Personalize your experience and offer targeted recommendations</li>
          <li>Communicate with you about our services, updates, and promotional offers</li>
          <li>Analyze usage patterns to enhance our app's functionality and user experience</li>
        </ul>

        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">Data Security</h2>
        <p className="mb-4">
          We implement appropriate technical and organizational measures to protect your personal data against unauthorized or unlawful processing, accidental loss, destruction, or damage. However, please note that no method of transmission over the internet or electronic storage is 100% secure.
        </p>

        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">Data Sharing and Disclosure</h2>
        <p className="mb-4">
          We do not sell your personal information. We may share your information with:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Service providers who assist us in operating our app and providing our services</li>
          <li>Legal authorities when required by law or to protect our rights</li>
          <li>Business partners, with your consent, for specific promotional activities</li>
        </ul>

        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">Your Rights</h2>
        <p className="mb-4">
          You have the right to:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Access, correct, or delete your personal data</li>
          <li>Object to processing or request restrictions on processing</li>
          <li>Data portability</li>
          <li>Withdraw consent at any time, where processing is based on consent</li>
        </ul>

        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">Changes to This Policy</h2>
        <p className="mb-4">
          We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the "last updated" date.
        </p>

        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions about this privacy policy or our data practices, please contact us at:
        </p>
        <p className="mb-4">
          Email: privacy@aicofounded.com
        </p>
        {/* TODO: Add actual contact email and any additional contact information */}

        <p className="mt-8 text-sm text-gray-600">
          Last updated: September 8, 2024
        </p>
        {/* TODO: Update the last updated date when finalizing the policy */}

        <button
          id="back-to-home-button"
          className="mt-8 bg-blue-900 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate('/')}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;