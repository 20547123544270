import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, X } from 'lucide-react';

interface MultiSelectProps {
  options: string[];
  selectedOptions: string[];
  onChange: (selected: string[]) => void;
  placeholder?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  selectedOptions,
  onChange,
  placeholder = 'Select options',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleOption = (option: string) => {
    const updatedSelection = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    onChange(updatedSelection);
  };

  const removeOption = (option: string) => {
    onChange(selectedOptions.filter((item) => item !== option));
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div
        className="bg-white border border-gray-300 rounded-md p-2 flex flex-wrap items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOptions.length === 0 ? (
          <span className="text-gray-400">{placeholder}</span>
        ) : (
          selectedOptions.map((option) => (
            <span
              key={option}
              className="bg-blue-100 text-blue-800 text-sm font-semibold mr-2 mb-2 px-2 py-1 rounded-full flex items-center"
            >
              {option}
              <X
                size={14}
                className="ml-1 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  removeOption(option);
                }}
              />
            </span>
          ))
        )}
        <ChevronDown size={20} className="ml-auto" />
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {options.map((option) => (
            <div
              key={option}
              className={`p-2 hover:bg-gray-100 cursor-pointer ${
                selectedOptions.includes(option) ? 'bg-blue-50' : ''
              }`}
              onClick={() => toggleOption(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;