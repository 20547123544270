import React, { useState } from "react";
import { Pencil, Save, X } from "lucide-react";
import Input from "../../common/Input";
import MyButton from "../../common/MyButton";
import { Project, ProjectStringOrNullFields } from "../../../types/supabase";

interface EditableFieldProps {
  field: ProjectStringOrNullFields;
  label: string;
  project: Project;
  onSave: (
    field: ProjectStringOrNullFields,
    value: string,
    project: Project
  ) => void;
  isTitle?: boolean; // New prop to determine if the text is a title
}

const EditableField: React.FC<EditableFieldProps> = ({
  field,
  label,
  project,
  onSave,
  isTitle = false, // Default value is false
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(project[field] || "");

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => {
    setIsEditing(false);
    setValue(project[field] || "");
  };
  const handleSave = () => {
    onSave(field, value, project);
    setIsEditing(false);
  };

  return (
    <div className="mb-6">
      <div className="flex items-center mb-2">
        <h2
          className={`text-xl font-semibold mr-2 ${isTitle ? "text-xl" : ""}`}
        >
          {label}
        </h2>
        {!isEditing && (
          <button
            onClick={handleEdit}
            className="text-gray-500 hover:text-blue-600 transition-colors"
            aria-label={`Edit ${label}`}
          >
            <Pencil size={18} />
          </button>
        )}
      </div>
      {isEditing ? (
        <div>
          <Input
            id={`edit-${field}`}
            label=""
            value={value}
            onChange={(e) => setValue(e.target.value)}
            as={field === "description" ? "textarea" : "input"}
            className="mb-4 border border-gray-300 w-full md:w-1/2 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <div className="flex space-x-2">
            <MyButton
              id={`save-${field}`}
              onClick={handleSave}
              className="flex items-center"
            >
              <Save
                size={18}
                className="mr-1"
              />{" "}
              Save
            </MyButton>
            <MyButton
              id={`cancel-${field}`}
              onClick={handleCancel}
              variant="secondary"
              className="flex items-center"
            >
              <X
                size={18}
                className="mr-1"
              />{" "}
              Cancel
            </MyButton>
          </div>
        </div>
      ) : (
        <p
          className={`text-gray-700 ${isTitle ? "text-blue-700 text-5xl" : ""}`}
        >
          {project[field]}
        </p>
      )}
    </div>
  );
};

export default EditableField;
