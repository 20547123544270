import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { supabase } from "../services/supabaseClient";
import * as amplitude from "@amplitude/analytics-browser";
import { toast } from "react-toastify";
import MyButton from "../components/common/MyButton";
import { Rule } from "../types/supabase";
import { Copy } from "lucide-react";
import { useAuth } from "../hooks/useAuth";

const RulePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [rule, setRule] = useState<Rule | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const user = useAuth();

  useEffect(() => {
    fetchRule();
    amplitude.logEvent("RULE_PAGE_VIEWED", { rule_id: id });
  }, [id]);

  const fetchRule = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("rules")
        .select("*")
        .eq("id", id!)
        .single();

      if (error) throw error;

      setRule(data);
    } catch (error) {
      console.error("Error fetching rule:", error);
      setError("Failed to fetch rule. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyContent = () => {
    if (rule) {
      navigator.clipboard.writeText(rule.content);
      toast.success("Rule content copied to clipboard!");
      amplitude.logEvent("RULE_CONTENT_COPIED", { rule_id: id });
    }
  };

  if (isLoading) {
    return <div id="rule-page-loading">Loading...</div>;
  }

  if (error || !rule) {
    return <div id="rule-page-error">{error || "Rule not found"}</div>;
  }

  return (
    <div
      id="rule-page"
      className="container mx-auto px-4 py-8"
    >
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-blue-900">{rule.title}</h1>
        <MyButton
          id="copy-rule-content"
          className="flex flex-row items-center"
          onClick={handleCopyContent}
        >
          <Copy
            className="mr-2"
            size={16}
          />
          Copy Content
        </MyButton>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg mb-6">
        <pre className="whitespace-pre-wrap">{rule.content}</pre>
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-semibold text-blue-900 mb-2">Tags</h2>
        <div className="flex flex-wrap gap-2">
          {rule.tags.map((tag) => (
            <span
              key={tag}
              className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
            >
              {tag}
            </span>
          ))}
        </div>
      </div>

      {rule.urls && rule.urls.length > 0 && (
        <div>
          <h2 className="text-xl font-semibold text-blue-900 mb-2">URLs</h2>
          <ul className="list-disc list-inside">
            {rule.urls.map((url, index) => (
              <li key={index}>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  {url}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {rule.user_id == user.user?.id && (
        <Link
          to={`/rule/${id}/edit`}
          className="mt-8 inline-block text-blue-600 hover:underline"
        >
          Edit Rule
        </Link>
      )}
    </div>
  );
};

export default RulePage;
