import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "What makes AI Co-Founded different from traditional business planning tools?",
    answer: "AI Co-Founded is revolutionizing entrepreneurship by harnessing the power of cutting-edge AI to create comprehensive, data-driven business plans. Unlike traditional tools, our platform analyzes vast amounts of real-time market data, industry trends, and successful business models to generate tailored strategies. It's like having a team of expert analysts, researchers, and strategists at your fingertips, 24/7!"
  },
  {
    question: "How does AI Co-Founded ensure my business plan is unique and tailored to my vision?",
    answer: "Our AI doesn't just generate generic plans – it's designed to understand and amplify your unique vision. By combining your input with our extensive data analysis, we create a symbiosis of human creativity and AI-powered insights. The result? A business plan that's as unique as your idea, backed by data-driven strategies that give you a competitive edge in today's dynamic market."
  },
  {
    question: "Can AI Co-Founded help me if I'm not tech-savvy?",
    answer: "Absolutely! We've designed AI Co-Founded with accessibility in mind. Our user-friendly interface guides you through the process step-by-step, translating complex business concepts into clear, actionable insights. Whether you're a tech wizard or a complete novice, our platform empowers you to create professional-grade business plans with ease. Your vision, our AI – together, we're democratizing access to high-quality business planning for entrepreneurs of all backgrounds!"
  },
  {
    question: "How often should I update my AI-generated business plan?",
    answer: "The business world is dynamic, and so is our AI! We recommend reviewing and updating your plan quarterly or whenever significant market changes occur. The beauty of AI Co-Founded is that you can easily regenerate sections of your plan with the latest data and insights, ensuring your strategy always stays ahead of the curve. It's not just a one-time plan; it's an evolving roadmap for your business success!"
  },
  {
    question: "What if I need help understanding parts of my AI-generated plan?",
    answer: "We're committed to your success every step of the way! If you need clarification on any part of your plan, our platform offers detailed explanations and context for each section. For more in-depth support, our team of business experts is just a click away. We offer personalized guidance to help you interpret the AI's insights and apply them effectively to your unique business situation. Remember, AI Co-Founded isn't just a tool – it's your partner in building a thriving business!"
  }
];

const FAQSection: React.FC = () => {
  const [openItems, setOpenItems] = useState<number[]>([]);

  const toggleItem = (index: number) => {
    setOpenItems(prevOpenItems =>
      prevOpenItems.includes(index)
        ? prevOpenItems.filter(item => item !== index)
        : [...prevOpenItems, index]
    );
  };

  return (
    <div id="faq-section" className="w-full max-w-3xl mx-auto mt-24">
      <h2 className="text-4xl font-bold mb-6 text-blue-900">Frequently Asked Questions</h2>
      {faqData.map((item, index) => (
        <div key={index} className="mb-4 border-b border-gray-200">
          <button
            className="flex justify-between items-center w-full py-4 text-left"
            onClick={() => toggleItem(index)}
          >
            <span className="text-lg font-medium text-blue-900">{item.question}</span>
            {openItems.includes(index) ? (
              <ChevronUp className="h-5 w-5 text-orange-600" />
            ) : (
              <ChevronDown className="h-5 w-5 text-orange-600" />
            )}
          </button>
          {openItems.includes(index) && (
            <p className="pb-4 text-gray-700">{item.answer}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQSection;