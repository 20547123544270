import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as amplitude from "@amplitude/analytics-browser";

const RequestAccountDeletionPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    amplitude.logEvent('REQUEST_ACCOUNT_DELETION_PAGE_VIEWED');
  }, []);

  return (
    <div id="request-account-deletion-page" className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-blue-900 mb-6">Request Account Deletion</h1>
      <div className="prose max-w-none">
        <p className="mb-4">
          We're sorry to see you go. If you wish to delete your AI Co-Founded account, please follow these steps:
        </p>

        <ol className="list-decimal list-inside mb-6">
          <li className="mb-3">Log in to your AI Co-Founded account on the mobile app.</li>
          <li className="mb-3">Go to the Settings or Account section.</li>
          <li className="mb-3">Look for an option related to account management or deletion. If available, follow the in-app instructions to initiate the deletion process.</li>
          <li className="mb-3">If you don't see an account deletion option in the app, or if you encounter any issues, proceed to step 5.</li>
          <li className="mb-3">
            Send an email to <a href="mailto:jtmuller5@gmail.com" className="text-blue-600 hover:underline">jtmuller5@gmail.com</a> with the following information:
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>Subject line: "Account Deletion Request"</li>
              <li>Your full name</li>
              <li>The email address associated with your AI Co-Founded account</li>
              <li>A brief reason for your deletion request (optional, but helpful for our records)</li>
            </ul>
          </li>
          <li className="mb-3">Wait for a confirmation email. We'll process your request and send you a confirmation once your account has been deleted.</li>
        </ol>

        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
          <p className="font-bold">Please note:</p>
          <p>Account deletion is permanent and cannot be undone. All your data, including your AI-generated business plans and personal information, will be permanently removed from our systems.</p>
        </div>

        <h2 className="text-2xl font-semibold text-blue-900 mt-6 mb-4">What happens after you request deletion?</h2>
        <ul className="list-disc list-inside mb-6">
          <li>We will verify your identity to ensure the request is legitimate.</li>
          <li>Once verified, we will process your deletion request within 30 days.</li>
          <li>After deletion, you will no longer be able to access your account or any associated data.</li>
          <li>We will send you a final confirmation email once the deletion process is complete.</li>
        </ul>

        <p className="mb-6">
          If you have any questions about the account deletion process, please don't hesitate to contact our support team at <a href="mailto:support@aicofounded.com" className="text-blue-600 hover:underline">support@aicofounded.com</a>.
        </p>

        <button
          id="back-to-home-button"
          className="mt-8 bg-blue-900 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate('/')}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default RequestAccountDeletionPage;