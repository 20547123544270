// src/pages/BlogPostPage.tsx
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import * as amplitude from "@amplitude/analytics-browser";
import { formatDateWithTime } from "../utils/dateUtils";
import MyButton from "../components/common/MyButton";
import { Bot } from "lucide-react";

interface BlogPost {
  title: string;
  date: string;
  content: string;
  isAIGenerated: boolean;
}

const BlogPostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      const response = await fetch(`/blog-json/${slug}.json`);
      const postData = await response.json();
      setPost(postData);
    };

    fetchPost();
    amplitude.logEvent("BLOG_POST_PAGE_VIEWED", { slug });
  }, [slug]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div
      id="blog-post-page"
      className="container mx-auto max-w-5xl px-4 py-8"
    >
      <div className="flex items-center mb-2">
        {post.isAIGenerated && (
          <Bot
            className="text-blue-900 mr-2"
            size={24}
          />
        )}
        <h1 className="text-3xl font-bold text-blue-900">{post.title}</h1>
      </div>
      <p className="text-gray-600 mb-6">{formatDateWithTime(post.date)}</p>
      <div className="prose max-w-none mb-8">
        <ReactMarkdown>{post.content}</ReactMarkdown>
      </div>
      <MyButton
        id="back-to-blog"
        onClick={() => navigate("/blog")}
        className="mt-4"
      >
        Back to Blog
      </MyButton>
    </div>
  );
};

export default BlogPostPage;
