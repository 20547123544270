import React from "react";
import { Link } from "react-router-dom";
import { format, formatDistanceStrict } from "date-fns";
import { Project } from "../../../types/supabase";

interface ProjectTileProps {
  project: Project;
  viewOnly?: boolean;
}

const ProjectTile: React.FC<ProjectTileProps> = ({
  project,
  viewOnly = false,
}) => {
  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "MMM d, yyyy");
  };

  const formatDateTime = (dateString: string) => {
    return format(new Date(dateString), "MMM d, yyyy h:mm a");
  };

  const calculateElapsedTime = (startDate: string, endDate?: string | null) => {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date();
    return formatDistanceStrict(start, end);
  };

  // Assuming project.created_at is the start time
  const startTime = new Date(project.created_at!);
  const currentTime = new Date();

  // Calculate the time 24 hours after the start time
  const timeAfter24Hours = new Date(startTime.getTime() + 24 * 60 * 60 * 1000);

  // Check if the current time is past the time 24 hours after the start time
  const overdue = (currentTime > timeAfter24Hours) && !project.completed;

  const completedOnTime = project.completed && !overdue;

  const linkTo = viewOnly
    ? `/project/${project.id}/view`
    : `/project/${project.id}`;

  return (
    <Link
      to={linkTo}
      className="block bg-white hover:bg-orange-600 shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-all duration-300 group"
    >
      <div
        id={`project-tile-${project.id}`}
        className="p-6"
      >
        <h2 className="text-xl font-semibold text-blue-900 group-hover:text-white mb-2 transition-colors duration-300">
          {project.name}
        </h2>
        {completedOnTime && (
          <div className="bg-green-100 text-green-800 text-xs font-semibold px-2 py-1 rounded-full mb-2 inline-block group-hover:bg-green-200 group-hover:text-green-900">
            Completed
          </div>
        )}
        {project.completed && (currentTime > timeAfter24Hours)&& (
          <div className="bg-yellow-100 mx-2 text-yellow-800 text-xs font-semibold px-2 py-1 rounded-full mb-2 inline-block group-hover:bg-red-200 group-hover:text-red-900">
            Late
          </div>
        )}
        {overdue && (
          <div className="bg-red-100 text-red-800 text-xs font-semibold px-2 py-1 rounded-full mb-2 inline-block group-hover:bg-red-200 group-hover:text-red-900">
            Overdue
          </div>
        )}
        <div className="flex items-start mb-3">
          <div className="w-2 h-8 group-hover:bg-blue-900 bg-orange-500 mt-1 mr-2"></div>
          <p className="mt-1 text-xs text-gray-600 group-hover:text-orange-100 transition-colors duration-300">
            {project.tagline || project.description?.substring(0, 100)}...
          </p>
        </div>
        <div className="mt-2 text-xs text-gray-400 group-hover:text-orange-200 transition-colors duration-300">
          <p>Started: {formatDateTime(project.created_at!)}</p>
          {project.completed && project.end_time && (
            <p>Completed: {formatDateTime(project.end_time)}</p>
          )}
          <p>
            {project.completed ? "Total time: " : "Elapsed: "}
            {calculateElapsedTime(project.created_at!, project.end_time)}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ProjectTile;
