import React, { useState } from "react";
import { supabase } from "../services/supabaseClient";
import * as amplitude from "@amplitude/analytics-browser";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import MyButton from "../components/common/MyButton";
import Input from "../components/common/Input";
import { toast } from "react-toastify";

export const PREDEFINED_CATEGORIES = [
  "Authentication",
  "Analytics",
  "Navigation",
  "Framework",
  "Database",
  "File Storage",
  "AB Testing",
  "State Management",
  "UI Components",
  "API Integration",
  "Testing",
  "Deployment",
  "Monitoring",
  "Performance",
  "Security",
];

export const LANGUAGES = [
  "Dart",
  "JavaScript",
  "TypeScript",
  "Python",
  "Java",
  "C#",
  "Ruby",
  "Go",
  "Rust",
  "PHP",
  "Swift",
  "Kotlin",
  "Objective-C",
  "Scala",
  "Perl",
  "Haskell",
  "Clojure",
  "Elixir",
  "R",
  "Julia",
  "Lua",
  "Shell",
  "PowerShell",
  "SQL",
  "NoSQL",
];

const CreateRulePage: React.FC = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [customTags, setCustomTags] = useState("");
  const [urls, setUrls] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [characterCount, setCharacterCount] = useState(0);

  const { user } = useAuth();
  const navigate = useNavigate();

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setContent(newContent);
    setCharacterCount(newContent.length);
  };

  const handleCategoryClick = (category: string) => {
    if (selectedCategory === category) {
      setSelectedCategory(null);
      setTags(tags.filter((tag) => tag !== category));
    } else {
      setSelectedCategory(category);
      setTags([
        ...tags.filter((tag) => !PREDEFINED_CATEGORIES.includes(tag)),
        category,
      ]);
    }
  };

  const handleLanguageClick = (language: string) => {
    if (selectedLanguage === language) {
      setSelectedLanguage(null);
      setTags(tags.filter((tag) => tag !== language));
    } else {
      setSelectedLanguage(language);
      setTags([...tags.filter((tag) => !LANGUAGES.includes(tag)), language]);
    }
  };

  const handleCustomTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTags(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!user) {
      setError("You must be logged in to create a rule.");
      setIsLoading(false);
      return;
    }

    const allTags = [
      ...tags.map((tag) => tag.trim().toLowerCase()),
      ...customTags
        .split(",")
        .map((tag) => tag.trim().toLowerCase())
        .filter((tag) => tag !== ""),
    ];

    const uniqueTags = Array.from(new Set(allTags));

    const urlsArray = urls
      .split(",")
      .map((url) => url.trim())
      .filter((url) => url !== "");

    try {
      const { data, error } = await supabase.from("rules").insert([
        {
          title,
          content,
          tags: uniqueTags,
          urls: urlsArray,
          user_id: user.id,
        },
      ]);

      if (error) throw error;

      toast.success("Rule added successfully!");
      amplitude.logEvent("RULE_ADDED", { tags: uniqueTags });

      // Clear form
      setTitle("");
      setContent("");
      setTags([]);
      setCustomTags("");
      setUrls("");
      setSelectedCategory(null);
      setSelectedLanguage(null);
      setCharacterCount(0);
    } catch (error) {
      console.error("Error adding rule:", error);
      setError("Failed to add rule. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      id="create-rule-page"
      className="container mx-auto px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-blue-900 mb-6">Add New Rule</h1>

      <form
        onSubmit={handleSubmit}
        className="space-y-6"
      >
        <Input
          id="title"
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <div className="relative">
          <Input
            id="content"
            label="Content"
            value={content}
            onChange={handleContentChange}
            required
            as="textarea"
            rows={5}
          />
          <div className="absolute bottom-2 right-2 text-sm text-gray-500">
            {characterCount} characters
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-blue-900 mb-2">Category</h2>
          <div className="flex flex-wrap gap-2 mb-4">
            {PREDEFINED_CATEGORIES.map((category) => (
              <button
                key={category}
                type="button"
                onClick={() => handleCategoryClick(category)}
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  selectedCategory === category
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-blue-900 mb-2">Language</h2>
          <div className="flex flex-wrap gap-2 mb-4">
            {LANGUAGES.map((language) => (
              <button
                key={language}
                type="button"
                onClick={() => handleLanguageClick(language)}
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  selectedLanguage === language
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {language}
              </button>
            ))}
          </div>
        </div>

        <Input
          id="custom-tags"
          label="Additional Tags (comma-separated)"
          value={customTags}
          onChange={handleCustomTagsChange}
        />

        <Input
          id="urls"
          label="URLs (comma-separated)"
          value={urls}
          onChange={(e) => setUrls(e.target.value)}
        />

        {error && <p className="text-red-600">{error}</p>}

        <MyButton
          id="submit-rule"
          type="submit"
          disabled={isLoading}
          isLoading={isLoading}
        >
          Add Rule
        </MyButton>
      </form>
    </div>
  );
};

export default CreateRulePage;
