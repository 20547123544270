import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../common/Logo";
import AuthButton from "../common/AuthButton";
import { useAuth } from "../../hooks/useAuth";
import { Menu, X } from "lucide-react";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { user } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = user
    ? [
        { name: "Dashboard", href: "/dashboard" },
        { name: "Plans", href: "/plans" },
        { name: "Pricing", href: "/pricing" },
        { name: "Blog", href: "/blog" },
      ]
    : [
        { name: "About", href: "/about" },
        { name: "Plans", href: "/plans" },
        { name: "Pricing", href: "/pricing" },
        { name: "Blog", href: "/blog" },
      ];

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header
      id="main-header"
      className="bg-white shadow-sm sticky top-0 z-10"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <Link
            to={"/"}
            className="flex items-center"
          >
            <Logo
              size="small"
              showShadow={false}
            />
            <span className="ml-2 text-xl font-bold text-gray-900 max-sm:hidden">
              AI Co-Founded
            </span>
          </Link>

          <div className="flex items-center">
            {/* AuthButton - always visible */}
            <AuthButton className="mr-2 sm:mr-4" />

            {/* Mobile menu button */}
            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900"
              >
                {isMenuOpen ? (
                  <X
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                ) : (
                  <Menu
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>

            {/* Desktop navigation */}
            <nav className="hidden md:flex">
              <ul className="flex space-x-4 items-center">
                {navItems.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className="text-gray-500 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                      onClick={(e) => {
                        if (item.href[0] === "#") {
                          e.preventDefault();
                          const element = document.getElementById(
                            item.href.slice(1)
                          );
                          element?.scrollIntoView({ behavior: "smooth" });
                        }
                      }}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>

        {/* Mobile navigation */}
        {isMenuOpen && (
          <nav className="md:hidden">
            <ul className="pt-2 pb-3 space-y-1">
              {navItems.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    onClick={(e) => {
                      if (item.href[0] === "#") {
                        e.preventDefault();
                        const element = document.getElementById(
                          item.href.slice(1)
                        );
                        element?.scrollIntoView({ behavior: "smooth" });
                      }
                      setIsMenuOpen(false);
                    }}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
