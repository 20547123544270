import React, { useState } from "react";
import { Copy, ExternalLink, Maximize2, Edit } from "lucide-react";
import { Link } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import Modal from "../../common/Modal"; // Adjust the import path as needed
import { toast } from "react-toastify";

export interface Rule {
  id: string;
  title: string;
  content: string;
  tags: string[];
  urls: string[] | null;
}

interface RuleTileProps {
  doc: Rule;
}

const RuleTile: React.FC<RuleTileProps> = ({ doc }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(doc.content);
    amplitude.logEvent("RULE_COPIED", { id: doc.id });
    toast.success("Rule copied to clipboard");
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    amplitude.logEvent("RULE_EXPANDED", {
      id: doc.id,
      expanded: !isExpanded,
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
    amplitude.logEvent("RULE_MODAL_OPENED", { id: doc.id });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    amplitude.logEvent("RULE_MODAL_CLOSED", { id: doc.id });
  };

  return (
    <div
      id={`doc-tile-${doc.id}`}
      className="bg-white p-4 rounded-lg shadow-md flex flex-col"
    >
      <div className="flex justify-between items-start">
        <Link to={`/rule/${doc.id}`}>
          <h2 className="text-xl font-semibold text-blue-900 mb-2">
            {doc.title}
          </h2>
        </Link>
      </div>
      <div className="mb-2 flex flex-wrap gap-1">
        {doc.tags.map((tag) => (
          <span
            key={tag}
            className="px-2 py-1 bg-gray-200 text-sm rounded"
          >
            {tag}
          </span>
        ))}
      </div>
      <pre className="bg-gray-100 p-2 rounded mb-4 overflow-x-auto grow">
        <code>{doc.content.substring(0, 200)}...</code>
      </pre>

      <div className="flex justify-start items-end gap-2">
        <Link
          to={`/rule/${doc.id}/edit`}
          className="flex items-center text-blue-600 hover:text-blue-800 mr-2"
        >
          <Edit
            size={20}
            className="mr-1"
          />
          Edit
        </Link>
        <button
          onClick={copyToClipboard}
          className="flex items-center text-green-600 hover:text-green-800"
        >
          <Copy
            size={20}
            className="mr-1"
          />
          Copy
        </button>
        <div className="flex flex-row justify-end">
          {doc.urls && doc.urls.length > 0 && (
            <div>
              {doc.urls.map((url, index) => (
                <a
                  key={index}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-blue-600 hover:text-blue-800 ml-2"
                >
                  <ExternalLink
                    size={20}
                    className="mr-1"
                  />
                  URL {index + 1}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={doc.title}
        onCopy={copyToClipboard}
      >
        <pre className="bg-gray-100 p-4 rounded overflow-x-auto whitespace-pre-wrap">
          <code>{doc.content}</code>
        </pre>
      </Modal>
    </div>
  );
};

export default RuleTile;
