// src/pages/NewProjectPage.tsx

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import MyButton from "../components/common/MyButton";
import Input from "../components/common/Input";
import { supabase } from "../services/supabaseClient";
import * as amplitude from "@amplitude/analytics-browser";
import { LANGUAGES } from "./CreateRulePage";
import { Rule } from "../types/supabase";

interface CategoryOptions {
  [key: string]: Rule[];
}

const AI_TOOLS = [
  "ChatGPT",
  "Claude",
  "Gemini",
  "GitHub Copilot",
  "Cursor",
  "v0",
  "Zed AI",
];

const PREDEFINED_CATEGORIES = [
  "Framework",
  "Authentication",
  "Analytics",
  "Navigation",
  "Database",
  "File Storage",
  "AB Testing",
  "State Management",
  "UI Components",
  "API Integration",
];

const NewProjectPage: React.FC = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedAITools, setSelectedAITools] = useState<string[]>([]);
  const [selectedDocs, setSelectedDocs] = useState<string[]>([]);
  const [availableDocs, setAvailableDocs] = useState<Rule[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<CategoryOptions>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    fetchRules();
  }, []);

  useEffect(() => {
    filterRules();
  }, [selectedLanguages, availableDocs]);

  const fetchRules = async () => {
    const { data, error } = await supabase.from("rules").select("*");
    if (error) {
      console.error("Error fetching rules:", error);
    } else if (data) {
      setAvailableDocs(data);
    }
  };

  const filterRules = () => {
    if (selectedLanguages.length > 0) {
      const filtered = availableDocs.filter((doc) =>
        doc.tags.some((tag) => selectedLanguages.includes(tag.toLowerCase()))
      );

      const categories = PREDEFINED_CATEGORIES.reduce((acc, category) => {
        acc[category] = filtered.filter((doc) =>
          doc.tags.some((tag) => tag.toLowerCase() === category.toLowerCase())
        );
        return acc;
      }, {} as CategoryOptions);

      setCategoryOptions(categories);
    } else {
      setCategoryOptions({});
    }
  };

  const handleLanguageClick = (language: string) => {
    setSelectedLanguages((prev) => {
      if (prev.includes(language)) {
        return prev.filter((lang) => lang !== language);
      } else {
        return [...prev, language];
      }
    });
  };

  const handleAIToolClick = (tool: string) => {
    setSelectedAITools((prev) => {
      if (prev.includes(tool)) {
        return prev.filter((t) => t !== tool);
      } else {
        return [...prev, tool];
      }
    });
  };

  const handleRuleToggle = (docId: string, category: string) => {
    setSelectedDocs((prev) => {
      const otherDocs = prev.filter(
        (id) => !categoryOptions[category].some((doc) => doc.id === id)
      );
      return [...otherDocs, docId];
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      setError("You must be logged in to create a project");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { data, error: projectError } = await supabase
        .from("projects")
        .insert([
          {
            name,
            description,
            user_id: user.id,
            languages: selectedLanguages,
            ai_tools: selectedAITools,
            start_time: new Date().toISOString(),
            end_time: null,
            completed: false,
          },
        ])
        .select()
        .single();

      if (projectError) throw projectError;

      if (data) {
        // Associate selected rules with the project
        const projectDocs = selectedDocs.map((docId) => ({
          project_id: data.id,
          rule_id: docId,
        }));

        const { error: docsError } = await supabase
          .from("project_rules")
          .insert(projectDocs);

        if (docsError) throw docsError;
      }

      amplitude.logEvent("PROJECT_CREATED", {
        languages: selectedLanguages,
        ai_tools: selectedAITools,
        docs_count: selectedDocs.length,
      });

      navigate("/dashboard");
    } catch (error) {
      console.error("Error creating project:", error);
      setError("Failed to create project. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      id="new-project-page"
      className="container mx-auto max-w-5xl px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-blue-900 mb-6">
        Start New Project
      </h1>
      <form
        onSubmit={handleSubmit}
        className="space-y-6"
      >
        <Input
          id="project-name"
          label="Project Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Input
          id="project-description"
          label="Project Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          as="textarea"
          rows={4}
        />
        <div>
          <h2 className="text-xl font-semibold text-blue-900 mb-2">
            Languages
          </h2>
          <div className="flex flex-wrap gap-2 mb-4">
            {LANGUAGES.map((language) => (
              <button
                key={language}
                type="button"
                onClick={() => handleLanguageClick(language)}
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  selectedLanguages.includes(language)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {language}
              </button>
            ))}
          </div>
        </div>
        <div>
          <h2 className="text-xl font-semibold text-blue-900 mb-2">
            AI Tools Used
          </h2>
          <div className="flex flex-wrap gap-2 mb-4">
            {AI_TOOLS.map((tool) => (
              <button
                key={tool}
                type="button"
                onClick={() => handleAIToolClick(tool)}
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  selectedAITools.includes(tool)
                    ? "bg-green-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {tool}
              </button>
            ))}
          </div>
        </div>
        {error && <p className="text-red-600 mb-4">{error}</p>}
        <MyButton
          id="create-project-button"
          type="submit"
          isLoading={isLoading}
          disabled={
            isLoading ||
            !name ||
            !description ||
            selectedLanguages.length === 0 ||
            selectedAITools.length === 0 ||
            selectedDocs.length === 0
          }
        >
          Start Project
        </MyButton>
      </form>
    </div>
  );
};

export default NewProjectPage;
