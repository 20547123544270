import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { Button } from "../components/ui/button";
import { supabase } from "../services/supabaseClient";
import { useAuth } from "../hooks/useAuth";
import { Loader } from "lucide-react";

const BusinessNamePage: React.FC = () => {
  const [businessIdea, setBusinessIdea] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [suggestedNames, setSuggestedNames] = useState<string[]>([]);
  const [isGeneratingNames, setIsGeneratingNames] = useState(false);
  const [isGeneratingPlan, setIsGeneratingPlan] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const storedIdea = localStorage.getItem("businessIdea");
    const storedName = localStorage.getItem("businessName");
    if (storedIdea) setBusinessIdea(storedIdea);
    if (storedName) setBusinessName(storedName);
    amplitude.logEvent("BUSINESS_NAME_PAGE_VIEWED");
  }, []);

  const handleGenerateNames = async () => {
    if (!businessIdea) {
      alert("Please enter a business idea first");
      return;
    }

    setIsGeneratingNames(true);
    try {
      const { data, error } = await supabase.functions.invoke(
        "ai-completions",
        {
          body: {
            query: `Generate 5 creative business name suggestions for the following business idea: ${businessIdea}`,
            provider: "openai",
          },
        }
      );

      if (error) throw error;

      const names = data.completion
        .split("\n")
        .filter((name: string) => name.trim() !== "")
        .map((name: string) => name.replace(/^\d+\.\s*/, "").trim());

      setSuggestedNames(names);
      amplitude.logEvent("BUSINESS_NAMES_GENERATED");
    } catch (error) {
      console.error("Error generating business names:", error);
      alert("Failed to generate business names. Please try again.");
    } finally {
      setIsGeneratingNames(false);
    }
  };

  const handleNameSelection = (name: string) => {
    setBusinessName(name);
    localStorage.setItem("businessName", name);
  };

  const generateMiniPlan = async () => {
    const prompt = `Generate a concise mini business plan for the following business idea: "${businessIdea}" with the business name "${businessName}". The plan should include the following sections:

1. Executive Summary (1-2 paragraphs)
2. Product or Service Offering (1 paragraph)
3. Target Market (1 paragraph)
4. Marketing and Sales Strategy (1 paragraph)
5. Business Model and Revenue Streams (1 paragraph)
6. Competitive Analysis (1 paragraph)
7. Financial Overview (1 paragraph)
8. Operations Overview (1 paragraph)
9. Team Overview (1 paragraph)
10. Risk and Mitigation (1 paragraph)

Each section should be brief and to the point, focusing on the most critical aspects of the business. The entire plan should not exceed 2 pages when formatted.`;

    try {
      const { data, error } = await supabase.functions.invoke(
        "ai-completions",
        {
          body: {
            query: prompt,
            provider: "openai",
          },
        }
      );

      if (error) throw error;

      return data.completion;
    } catch (error) {
      console.error("Error generating mini plan:", error);
      throw error;
    }
  };

  const handleGenerate = async () => {
    if (!user) {
      alert("You must be logged in to generate a business plan");
      return;
    }

    if (!businessIdea || !businessName) {
      alert("Please enter both a business idea and name");
      return;
    }

    setIsGeneratingPlan(true);

    try {
      const miniPlan = await generateMiniPlan();

      // Create a new plan
      const { data: plan, error: planError } = await supabase
        .from("plans")
        .insert({
          user_id: user.id,
          name: businessName,
          idea: businessIdea,
          mini_plan: miniPlan,
          is_public: true, // Set to true by default
        })
        .select()
        .single();

      if (planError) throw planError;

      localStorage.removeItem("businessIdea");
      amplitude.logEvent("MINI_BUSINESS_PLAN_GENERATED", {
        plan_id: plan.id,
        is_public: true,
      });

      navigate(`/plan/${plan.id}`);
    } catch (error: any) {
      console.error("Error generating plan:", error);
      alert(`An error occurred while generating the plan. ${error.error}`);
    } finally {
      setIsGeneratingPlan(false);
    }
  };

  return (
    <div
      id="business-name-page"
      className="container mx-auto max-w-2xl px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-blue-900 mb-6">
        Choose Your Business Name
      </h1>
      <div className="space-y-6">
        <div>
          <label
            htmlFor="business-name"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Business Name
          </label>
          <input
            id="business-name"
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
            placeholder="Enter business name"
            required
          />
        </div>
        {suggestedNames.length > 0 && (
          <div>
            <h2 className="text-xl font-semibold mb-2">Suggested Names:</h2>
            <ul className="space-y-2">
              {suggestedNames.map((name, index) => (
                <li key={index}>
                  <Button
                    onClick={() => handleNameSelection(name)}
                    className="w-full text-left bg-gray-100 hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded"
                  >
                    {name}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <Button
          id="generate-plan-button"
          onClick={handleGenerate}
          disabled={!businessName.trim() || isGeneratingPlan}
          className="w-full bg-green-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-500 flex items-center justify-center"
        >
          {isGeneratingPlan ? (
            <>
              <Loader
                className="animate-spin mr-2"
                size={20}
              />
              Generating Mini Plan...
            </>
          ) : (
            "Generate Mini Business Plan"
          )}
        </Button>
      </div>
    </div>
  );
};

export default BusinessNamePage;
