import React from "react";
import { motion } from "framer-motion";
import { Building, Package, BarChart2, TrendingUp, FileSpreadsheet, DollarSign } from "lucide-react";

interface FeatureItem {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface FeaturesSectionProps {
  label: string;
  title: string;
  subtitle: string;
}

const FeatureIcon: React.FC<{ icon: React.ReactNode }> = ({ icon }) => (
  <div className="bg-blue-900 rounded-lg p-2 w-12 h-12 flex items-center justify-center">
    {icon}
  </div>
);

const FeatureCard: React.FC<FeatureItem> = ({ icon, title, description }) => (
  <motion.div 
    className="flex gap-4"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true }}
  >
    <FeatureIcon icon={icon} />
    <div>
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </motion.div>
);

const FeaturesSection: React.FC<FeaturesSectionProps> = ({
  label,
  title,
  subtitle,
}) => {

const features: FeatureItem[] = [
  {
    icon: <Building className="w-6 h-6 text-white" />,
    title: "Strategic Vision",
    description: "Harness AI-powered insights to craft a comprehensive overview of your business concept, exploring innovative business models tailored to your unique idea.",
  },
  {
    icon: <Package className="w-6 h-6 text-white" />,
    title: "Product & Service Innovation",
    description: "Unlock a wealth of AI-generated product and service ideas, leveraging cutting-edge market trends to position your offerings for success.",
  },
  {
    icon: <BarChart2 className="w-6 h-6 text-white" />,
    title: "Data-Driven Market Analysis",
    description: "Dive deep into your target audience with AI-powered market research, uncovering hidden opportunities and potential customers.",
  },
  {
    icon: <TrendingUp className="w-6 h-6 text-white" />,
    title: "AI-Crafted Growth Strategies",
    description: "Deploy AI-engineered marketing and sales tactics designed to captivate your audience and drive business growth.",
  },
  {
    icon: <FileSpreadsheet className="w-6 h-6 text-white" />,
    title: "Optimized Operations",
    description: "Streamline your business with AI-optimized operational workflows, ensuring efficiency and scalability from launch day.",
  },
  {
    icon: <DollarSign className="w-6 h-6 text-white" />,
    title: "Predictive Financials",
    description: "Gain a competitive edge with AI-generated financial projections and funding strategies, tailored to your business's unique trajectory.",
  },
];

  return (
    <section id="features" className="py-16 px-4 max-w-7xl mx-auto">
      <motion.div 
        className="text-center mb-16"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.span 
          className="text-blue-800 font-semibold"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {label}
        </motion.span>
        <motion.h2 
          className="text-4xl font-bold mt-2 mb-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          {title}
        </motion.h2>
        <motion.p 
          className="text-xl text-gray-600 max-w-3xl mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          {subtitle}
        </motion.p>
      </motion.div>
      <div className="grid md:grid-cols-2 gap-12">
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            {...feature}
          />
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;