import React from "react";
import { Copy, Download } from "lucide-react";
import RuleTile, { Rule } from "./RuleTile";
import { toast } from "react-toastify";
import * as amplitude from "@amplitude/analytics-browser";
import MyButton from "../../common/MyButton";

interface ProjectRuleProps {
  projectId: string;
  projectName: string;
  rules: Rule[];
}

const ProjectRules: React.FC<ProjectRuleProps> = ({
  projectId,
  projectName,
  rules: rules,
}) => {
  const combineRulesContent = (): string => {
    return rules
      .map((doc) => `${doc.title}\n\n${doc.content}\n\n`)
      .join("---\n\n");
  };

  const handleCopyAll = () => {
    const combinedContent = combineRulesContent();
    navigator.clipboard.writeText(combinedContent).then(
      () => {
        toast.success("All rules copied to clipboard");
        amplitude.logEvent("RULES_COPIED", { project_id: projectId });
      },
      (err) => {
        console.error("Could not copy text: ", err);
        toast.error("Failed to copy rule");
      }
    );
  };

  const handleDownload = () => {
    const combinedContent = combineRulesContent();
    const blob = new Blob([combinedContent], {
      type: "text/plain;charset=utf-8",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${projectName}_rules.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    amplitude.logEvent("RULES_DOWNLOADED", { project_id: projectId });
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-blue-900">Project Rules</h2>
        <div className="space-x-2 flex flex-row">
          <MyButton
            id="copy-all-docs"
            onClick={handleCopyAll}
            disabled={rules.length === 0}
            className="flex items-center"
          >
            <Copy
              className="mr-2"
              size={16}
            />
            Copy All
          </MyButton>
          <MyButton
            id="download-all-docs"
            onClick={handleDownload}
            disabled={rules.length === 0}
            className="flex items-center"
          >
            <Download
              className="mr-2"
              size={16}
            />
            Download
          </MyButton>
        </div>
      </div>

      {rules.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {rules.map((doc) => (
            <RuleTile
              key={doc.id}
              doc={doc}
            />
          ))}
        </div>
      ) : (
        <p className="text-gray-600">No rules associated with this project.</p>
      )}
    </div>
  );
};

export default ProjectRules;
